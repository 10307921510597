@media screen and (min-width: 768px) and (max-width: 1000px) {

    .mob-menue-container{
        display: block !important;
        position: absolute;
        background: #FFFFFF;
        top: 55%;
        right: 0%;
        z-index: 999999;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 30% !important;
        padding: 5px;
    }
    .mob-top-div{
        border-bottom: 1px solid #E9E9E9;
        padding: 10px;
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 15px;
        position: relative;
    }
    
    .mob-location-input{
        width: 100%;
        background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 5px;
    }
    .mob-location-input:focus{
        
        outline: none;
        border: 1px solid #00ADEF;
    }
    .mob-location-input::placeholder{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #666666;
    }
    .mob-arrow-img{
        position: absolute;
        width: 6%;
        right: 10%;
        top: 35%;    
    }
    .mob-middle-div{
        border-bottom: 1px solid #E9E9E9;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .mob-login-button,.mob-register-button{
        background: #FFFFFF;
    border: 1px solid #00ADEF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    width: 45%;
    }
    .mob-login-button:focus,.mob-register-button:focus{
        border: 1px solid #00ADEF;
    }
    .mob-lower-div{
        padding: 10px;
        padding-bottom: 0px;
    }
    .lmob-ower-div-text
    {font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        
        
        color: #333333;
    }
    
    /* .mob-contact-us{
        font-weight: 600;
        color: #000000;
    } */

}
@media screen and (max-width:767px) {
    

.mob-menue-container{
    display: block !important;
    position: absolute;
    background: #FFFFFF;
    top: 55%;
    right: 0%;
    z-index: 99;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 60%;
    padding: 5px;
}
.mob-top-div{
    border-bottom: 1px solid #E9E9E9;
    padding: 10px;
    padding-right: 0px;

    padding-left: 0px;
    padding-bottom: 15px;
    position: relative;
}

.mob-location-input{
    width: 100%;
    background: #FFFFFF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
border: none;
padding-left: 10px;
padding-top: 4px;
padding-bottom: 5px;
}
.mob-location-input:focus{
    
    outline: none;
    border: 1px solid #00ADEF;
}
.mob-location-input::placeholder{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #666666;
}
.mob-arrow-img{
    position: absolute;
    width: 6%;
    right: 10%;
    top: 35%;    
}
.mob-middle-div{
    border-bottom: 1px solid #E9E9E9;
    
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mob-login-button,.mob-register-button{
    background: #FFFFFF;
border: 1px solid #00ADEF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
border: none;
padding: 5px;
padding-left: 20px;
padding-right: 20px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #333333;
width: 45%;
}
.mob-login-button:focus,.mob-register-button:focus{
    border: 1px solid #00ADEF;
}
.mob-lower-div{
    padding: 10px;
    padding-bottom: 0px;
}
.mob-lower-div-text
{font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    
    color: #333333;
}

/* .mob-contact-us{
    font-weight: 600;
    color: #000000;
} */
 }
 .mob-menue-container{
    display: none;
 }