@media screen and (min-width: 768px) and (max-width: 1000px) {
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0px !important;
    padding-bottom: 15px !important;
    height: 50px !important;
    /* background-color: red !important; */
  }
  .header-title h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 10px !important;
  }
  .header-title h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 95px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .how-cards-container {
    flex-wrap: wrap;
    padding: 0px !important;
    /* background-color: red; */
    padding-top: 20px !important;
    justify-content: center !important;
    gap: 40px !important;
    margin-bottom: 0% !important ;
  }
  .how-card {
    width: 47% !important;
    height: 390px !important;
    background: rgba(0, 173, 239, 0.17);
    border-radius: 10px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    padding: 15px !important;
    margin: 0%;
    /* transform: translateX(10px); */
  }
  .how-card .how-card-title {
    width: 100%;
    height: 100%;
    /* background-color: orange; */
  }
  .how-card-2 {
    /* transform: translateX(-10px); */
  }
  .how-card-3 {
    /* transform: translateX(100px); */
  }
  .how-card .how-card-title h5 {
    font-family: "Poppins";
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #050f56;
    margin: 0;
  }

  /* 3rd img */
  .how-card .how-card-img {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    display: flex;
  }
  .how-card .how-card-img1 {
    width: 100%;
    height: 300px !important;
    /* background-color: red; */
    display: flex;
    padding-bottom: 7%;
  }
  .how-card .how-card-text p {
    font-family: "Roboto";
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #717171;
    line-height: 10px !important;
    margin: 0;
  }
  .how-card .how-card-text2 {
    width: 100%;
    height: 100%;
    /* background-color: orange; */
    /* margin-top: -28%; */
  }
  .how-card .how-card-text2 p {
    font-family: "Roboto";
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #717171;
    line-height: 10px !important;
    margin: 0;
  }
  .how-card .how-card-img2 {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    /* margin-top: 0%; */
    margin-bottom: 0px !important;
    /* padding-bottom: 48%; */
    /* padding-top: -20px; */
  }
}

@media screen and (max-width: 767px) {
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0px !important;
    padding-bottom: 15px !important;
    height: 50px !important;
    /* background-color: red !important; */
  }
  .header-title h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .header-title h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 80px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}
.header-title h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.header-title h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.how-cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  padding: 0rem 0px;
  text-align: center;
}

/* @media screen and (width:700px) {
  
  .how-card {
    width: 44.5% !important;
    height: 50vh !important;
    background: rgba(0, 173, 239, 0.17);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px !important;
    margin: 0%;
    
  }
}  */

@media screen and (max-width: 767px) {
  .how-cards-container {
    flex-wrap: wrap;
    padding: 0px !important;
    /* background-color: red; */
    padding-top: 15px !important;
    justify-content: center;
    gap: 20px !important;
    margin-bottom: -20px !important ;
    /* padding-bottom: -10px !important; */
  }
  .how-card {
    width: 44.5% !important;
    height: 230px !important;
    background: rgba(0, 173, 239, 0.17);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px !important;
    margin: 0%;
    /* transform: translateX(10px); */
  }
  .how-card .how-card-title {
    width: 100%;
    height: 100%;
    /* background-color: orange; */
  }
  .how-card-2 {
    /* transform: translateX(-10px); */
  }
  .how-card-3 {
    /* transform: translateX(100px); */
  }
  .how-card .how-card-title h5 {
    font-family: "Poppins";
    font-weight: 500 !important;
    font-size: 10px !important;
    color: #050f56;
    margin: 0;
  }

  /* 3rd img */
  .how-card .how-card-img {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    display: flex;
  }
  .how-card .how-card-img1 {
    width: 100%;
    height: 150px !important;
    /* background-color: red; */
    display: flex;
    padding-bottom: 7%;
  }
  .how-card .how-card-text p {
    font-family: "Roboto";
    font-weight: 500 !important;
    font-size: 9px !important;
    color: #717171;
    line-height: 10px !important;
    margin: 0;
  }
  .how-card .how-card-text2 {
    width: 100%;
    height: 100%;
    /* background-color: orange; */
    /* margin-top: -28%; */
  }
  .how-card .how-card-text2 p {
    font-family: "Roboto";
    font-weight: 500 !important;
    font-size: 9px !important;
    color: #717171;
    line-height: 10px !important;
    margin: 0;
  }
  .how-card .how-card-img2 {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    /* margin-top: 0%; */
    margin-bottom: 0px !important;
    /* padding-bottom: 48%; */
    /* padding-top: -20px; */
  }
}

.how-card {
  width: 380px;
  height: 500px;
  background: rgba(0, 173, 239, 0.17);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}
.how-card .how-card-title {
  width: 100%;
  height: 100%;
}
.how-card .how-card-title h5 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  color: #050f56;
  margin: 0;
}
.how-card .how-card-img {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
}
.how-card .how-card-img1 {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  padding-bottom: 7%;
}
.how-card .how-card-img2 {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  /* padding-bottom: 48%; */
  /* padding-top: -20px; */
}

.how-card .how-card-text {
  width: 100%;
  height: 100%;
}
.how-card .how-card-text2 {
  width: 100%;
  height: 100%;
  /* margin-top: -28%; */
}
.how-card .how-card-text2 p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #717171;
  margin: 0;
}
.how-card .how-card-text p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #717171;
  margin: 0;
}

/* Latest Vehicles Section */

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .latest-vehicle-section {
    background: #f4f4f4;
    margin-top: 3rem;
    padding-bottom: 30px !important;
  }
  .latest-vehicle-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
  .latest-vehicle-header h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .latest-vehicle-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 95px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .tablist-container {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    list-style-type: none;
    cursor: pointer;
    text-align: center !important;
    position: relative;
    padding: 0px 20px !important;
    /* width: 100%; */
    /* background-color: rgb(173, 233, 196) !important; */
  }

  .tablist-container > li {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 15px !important;
    color: #6b6b6b;
    position: relative;
    border: none;
    margin: 0px 0px 0px 0px;
  }
  .tablist-container::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    margin-top: 10px !important;
    background-color: #dddddd;
  }
  .tab.active-bar::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: 0px;
    width: 260px;
    height: 3px;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(1)::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: -100px;
    width: 220px !important;
    height: 3px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: -60px !important;
    width: 170px !important;
    height: 3px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: -70px !important;
    width: 200px !important;
    height: 3px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: -42px !important;
    width: 250px !important;
    height: 3px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .latest-card .latest-img-wrapper img {
    width: 100%;
    height: 250px !important;
    object-fit: cover;
    /* background-color: red; */
  }
  .latest-card .latest-info .latest-header h5 {
    font-family: "Poppins";
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56 !important;
    margin-top: 10px !important;
    width: 100px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .latest-card .latest-info .latest-header .latest-location {
    margin-top: -5px;
  }
  .latest-card .latest-info .latest-header .latest-location img {
    width: 15px !important;
    height: 15px !important;
    object-fit: cover;
  }
  .latest-card .latest-info .latest-header .latest-location span {
    display: inline-block;
    text-transform: capitalize;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 14px !important;
    color: #121212;
    margin-left: 4px !important;
  }
  .latest-card .latest-info button {
    margin-top: 15px !important;
    padding: 8px !important;
    width: 100%;
    border: none;
    background: #050f56;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 15px !important;
    color: #ffffff;
  }
  .latest-card .latest-info {
    padding: 10px !important;
  }
  .swiper-latest .swiper-button-prev {
    display: none !important;
  }
  .swiper-latest .swiper-button-next {
    display: none !important;
  }
  .swiper-latest {
    width: 100%;
    margin-top: 40px;
    padding: 0px 20px !important;
  }
  .latest-card {
    background: #ffffff;
    border-radius: 5px !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .latest-vehicle-section {
    background: #f4f4f4;
    margin-top: 3rem;
    padding-bottom: 30px !important;
  }
  .latest-vehicle-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
  .latest-vehicle-header h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .latest-vehicle-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 75px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .tablist-container {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    list-style-type: none;
    cursor: pointer;
    text-align: center !important;
    position: relative;
    padding: 0px 10px !important;
    /* width: 100%; */
    /* background-color: rgb(173, 233, 196) !important; */
  }

  .tablist-container > li {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 10px !important;
    color: #6b6b6b;
    position: relative;
    border: none;
    margin: 0px 0px 0px 0px;
  }
  .tablist-container::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    margin-top: 10px !important;
    background-color: #dddddd;
  }
  .tab.active-bar::after {
    content: "";
    position: absolute;
    top: 30px !important;
    left: 0px;
    width: 260px;
    height: 3px;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(1)::after {
    content: "";
    position: absolute;
    top: 25px !important;
    left: -100px;
    width: 155px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 25px !important;
    left: -20px !important;
    width: 80px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 25px !important;
    left: -20px !important;
    width: 100px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .tab.active-bar:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 25px !important;
    left: -10px;
    width: 140px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
    color: #000000;
    z-index: 50;
  }
  .latest-card .latest-img-wrapper img {
    width: 100%;
    height: 110px !important;
    object-fit: cover;
    /* background-color: red; */
  }
  .latest-card .latest-info .latest-header h5 {
    font-family: "Poppins";
    text-transform: capitalize;
    font-weight: 600;
    font-size: 8.5px !important;
    color: #050f56 !important;
    margin: 0;
    width: 100px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .latest-card .latest-info .latest-header .latest-location {
    margin-top: -5px;
  }
  .latest-card .latest-info .latest-header .latest-location img {
    width: 10px !important;
    height: 10px !important;
    object-fit: cover;
  }
  .latest-card .latest-info .latest-header .latest-location span {
    display: inline-block;
    text-transform: capitalize;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 10px !important;
    color: #121212;
    margin-left: 5px;
  }
  .latest-card .latest-info button {
    margin-top: 10px !important;
    padding: 5px !important;
    width: 100%;
    border: none;
    background: #050f56;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 9px !important;
    color: #ffffff;
  }
  .latest-card .latest-info {
    padding: 10px !important;
  }
  .swiper-latest .swiper-button-prev {
    display: none !important;
  }
  .swiper-latest .swiper-button-next {
    display: none !important;
  }
  .swiper-latest {
    width: 100%;
    margin-top: 40px;
    padding: 0px 10px !important;
  }
  .latest-card {
    background: #ffffff;
    border-radius: 5px !important;
    overflow: hidden;
  }
}

.latest-vehicle-section {
  background: #f4f4f4;
  margin-top: 3rem;
  padding-bottom: 50px;
}

.latest-vehicle-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}
.latest-vehicle-header h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.latest-vehicle-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.latest-card {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.latest-card .home-uid-overlay {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  padding: 0.5rem 1rem;
  background-color: #00adef;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.latest-card .home-uid-overlay span {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}
.latest-card .latest-img-wrapper img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.latest-card .latest-info {
  padding: 20px;
}
.latest-card .latest-info .latest-header .latest-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.latest-card .latest-info .latest-header h5 {
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
  color: #4a4a4a;
  margin: 0;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.latest-card .latest-info .latest-header p {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 20px;
  color: #00adef;
  margin: 0;
}
.latest-card .latest-info .latest-header .latest-location {
  margin-top: -5px;
}
.latest-card .latest-info .latest-header .latest-location img {
  width: 17px;
  height: 17px;
  object-fit: cover;
}
.latest-card .latest-info .latest-header .latest-location span {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
  color: #121212;
  margin-left: 5px;
}
.latest-card .latest-info button {
  margin-top: 10px;
  padding: 12px 20px;
  width: 100%;
  border: none;
  background: #050f56;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.swiper-latest {
  width: 100%;
  margin-top: 40px;
  padding: 0px 40px;
}
.swiper-latest .swiper-button-prev {
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.swiper-latest .swiper-button-prev::after {
  font-size: 30px;
  font-weight: bolder;
}

.swiper-latest .swiper-button-next {
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.swiper-latest .swiper-button-next::after {
  font-size: 30px;
  font-weight: bolder;
}

.tablist-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  list-style-type: none;
  cursor: pointer;
  text-align: center;
  position: relative;
  padding: 0px 40px;
}
.tablist-container::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: 17px;
  background-color: #dddddd;
}
.tablist-container > li {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  color: #6b6b6b;
  position: relative;
  border: none;
  margin: 0px 0px 0px 0px;
}

.tablist-container li:focus {
  border: none;
  outline: none;
}

.tab {
  position: relative;
}
.react-tabs__tab-panel--selected:focus {
  border: none;
}
.tab.active-bar::after {
  content: "";
  position: absolute;
  top: 45px;
  left: 0px;
  width: 260px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
  color: #000000;
  z-index: 50;
}
.tab.active-bar:nth-of-type(1)::after {
  content: "";
  position: absolute;
  top: 45px;
  left: -100px;
  width: 260px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
  color: #000000;
  z-index: 50;
}
.tab.active-bar:nth-of-type(2)::after {
  content: "";
  position: absolute;
  top: 45px;
  left: -100px;
  width: 260px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
  color: #000000;
  z-index: 50;
}
.tab.active-bar:nth-of-type(3)::after {
  content: "";
  position: absolute;
  top: 45px;
  left: -80px;
  width: 260px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
  color: #000000;
  z-index: 50;
}
.tab.active-bar:nth-of-type(4)::after {
  content: "";
  position: absolute;
  top: 45px;
  left: -10px;
  width: 280px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
  color: #000000;
  z-index: 50;
}

/* Our Categories */

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .our-categories-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    padding-top: 5px !important;
  }

  .our-categories-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .our-categories-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 95px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .our-categories-container {
    width: 100% !important;
    /* background-color: #fe620d; */
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 25px !important;

    align-items: center;
    justify-content: center !important;
    padding: 0px !important;
    padding-top: 15px !important;
  }
  .our-categories-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .our-category {
    border-radius: 5px !important;
    position: relative;
    overflow: hidden;
    /* padding: 0% !important; */
    height: 350px !important;
    width: 46% !important;
  }
  .our-category .our-category-title {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 86% !important;
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    padding: 0.8rem 1rem !important;
  }

  .our-category .our-category-title h6 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px !important;
    color: #ffffff;
    /* background-color: orange; */
  }
}

@media screen and (max-width: 767px) {
  .our-categories-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    padding-top: 5px !important;
  }

  .our-categories-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .our-categories-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 77px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .our-categories-container {
    width: 100% !important;
    /* background-color: #fe620d; */
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 15px !important;

    align-items: center;
    justify-content: center !important;
    padding: 0px !important;
    padding-top: 15px !important;
  }
  .our-categories-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .our-category {
    border-radius: 5px !important;
    position: relative;
    overflow: hidden;
    /* padding: 0% !important; */
    height: 10% !important;
    width: 45.5% !important;
  }
  .our-category .our-category-title {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 80% !important;
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    padding: 0.8rem 1rem !important;
  }

  .our-category .our-category-title h6 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 10px !important;
    color: #ffffff;
    /* background-color: orange; */
  }
}

.our-categories-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.our-categories-header h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.our-categories-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.our-categories-container {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0rem 2rem;
}
.our-categories-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.our-category {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.our-category .our-category-title {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 85%;
  background: rgba(255, 255, 255, 0.26);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  padding: 1rem 1rem;
}

.our-category .our-category-title h6 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}

/* Customer Review */

@media screen and (min-width: 0px) and (max-width: 819px) {
  .customer-review-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    padding-top: 20px !important;
  }

  .customer-review-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .customer-review-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 88px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .testimonial-slide {
    padding: 10px 10px !important;
    /* background-color: orange; */
  }
  .customer-review-container .customer-card {
    width: 100%;
    height: auto !important;
    display: flex;
    /* background-color: orange !important; */
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    padding: 25px 10px !important;
  }
  .customer-review-container {
    display: flex;
    align-items: center;
    padding: 0rem 10px !important;
  }
}

@media screen and (min-width: 820px) and (max-width: 1000px) {
  .customer-review-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    padding-top: 20px !important;
  }

  .customer-review-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .customer-review-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 98px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .customer-review-container {
    display: flex;
    align-items: center;
    padding: 0rem 0rem !important;
  }

  .customer-review-container .customer-card {
    width: 100%;
    height: auto !important;
    display: flex;
    /* background-color: orange !important; */
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    padding: 25px 10px !important;
  }
  .customer-review-container .customer-card .profile-img img {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50%;
  }
  .customer-review-container .customer-card .profile-name-location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px !important;
  }
  .customer-review-container .customer-card .profile-name-location h6 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 12px !important;
    text-align: center;
    color: #050f56;
    padding: 0;
    margin: 0;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location {
    display: flex;
    align-items: center;
    margin-top: 2px !important;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location
    img {
    width: 10px !important;
    height: 10px !important;
    object-fit: cover;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location
    span {
    margin-left: 3px !important;
    display: inline-block;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 10px !important;
    color: #121212;
    text-transform: capitalize;
  }
  .customer-review-container .customer-card .profile-line {
    width: 100%;
    height: 0.5px !important;
    background-color: #cecece;
    margin: 10px 0px !important;
  }
  .testimonial-slide {
    padding: 20px 20px !important;
    /* background-color: orange; */
    /* background-color: orange; */
  }

  .customer-review-container .customer-card .profile-review p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px !important;
    color: #8f8f8f;
    text-align: center !important;
  }
}

@media screen and (max-width: 767px) {
  .customer-review-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    padding-top: 20px !important;
  }

  .customer-review-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .customer-review-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 80px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .customer-review-container {
    display: flex;
    align-items: center;
    padding: 0rem 0rem !important;
  }

  .customer-review-container .customer-card {
    width: 100%;
    height: auto !important;
    display: flex;
    /* background-color: orange !important; */
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    padding: 25px 10px !important;
  }
  .customer-review-container .customer-card .profile-img img {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50%;
  }
  .customer-review-container .customer-card .profile-name-location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px !important;
  }
  .customer-review-container .customer-card .profile-name-location h6 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 12px !important;
    text-align: center;
    color: #050f56;
    padding: 0;
    margin: 0;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location {
    display: flex;
    align-items: center;
    margin-top: 2px !important;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location
    img {
    width: 10px !important;
    height: 10px !important;
    object-fit: cover;
  }
  .customer-review-container
    .customer-card
    .profile-name-location
    .profile-location
    span {
    margin-left: 3px !important;
    display: inline-block;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 10px !important;
    color: #121212;
    text-transform: capitalize;
  }
  .customer-review-container .customer-card .profile-line {
    width: 100%;
    height: 0.5px !important;
    background-color: #cecece;
    margin: 10px 0px !important;
  }
  .testimonial-slide {
    padding: 20px 10px !important;
    /* background-color: orange; */
    /* background-color: orange; */
  }

  .customer-review-container .customer-card .profile-review p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 10px !important;
    color: #8f8f8f;
    text-align: center !important;
  }
}

.customer-review-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.customer-review-header h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.customer-review-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.customer-review-container {
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
}

.customer-review-container .customer-card {
  width: 400px;
  /* height: 578px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 20px;
}
.customer-review-container .customer-card .profile-img img {
  width: 132px;
  height: 132px;
  object-fit: cover;
  border-radius: 50%;
}
.customer-review-container .customer-card .profile-name-location {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
}
.customer-review-container .customer-card .profile-name-location h6 {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #050f56;
  padding: 0;
  margin: 0;
}
.customer-review-container
  .customer-card
  .profile-name-location
  .profile-location {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.customer-review-container
  .customer-card
  .profile-name-location
  .profile-location
  img {
  width: 17px;
  height: 17px;
  object-fit: cover;
}
.customer-review-container
  .customer-card
  .profile-name-location
  .profile-location
  span {
  margin-left: 5px;
  display: inline-block;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
  color: #121212;
  text-transform: capitalize;
}
.customer-review-container .customer-card .profile-line {
  width: 100%;
  height: 1px;
  background-color: #cecece;
  margin: 20px 0px;
}
.testimonial-slide {
  padding: 20px 20px;
}

.customer-review-container .customer-card .profile-review p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #8f8f8f;
}

/* Trusted Brands */

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .trusted-brands-section {
    background: white !important;
    margin-top: 0rem !important;
    padding-bottom: 15px !important;
  }

  .trusted-brand-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px !important;
  }
  .trusted-brand-header h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .trusted-brand-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 95px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .trusted-brands-container {
    padding-left: 20px !important;
  }

  .trusted-brands-container .brand-wrapper {
    width: 100% !important;
    height: 150px !important;
    background: #ffffff;
    /* background-color: orange !important; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
  }

  .trusted-brands-container .brand-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .brands-swiper-slide {
    padding: 2px !important;
    /* background-color: green; */
    /* gap: 10px !important; */
  }
}

@media screen and (max-width: 767px) {
  .trusted-brands-section {
    background: white !important;
    margin-top: 0rem !important;
    padding-bottom: 15px !important;
  }

  .trusted-brand-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px !important;
  }
  .trusted-brand-header h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .trusted-brand-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 75px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .trusted-brands-container {
    padding-left: 10px !important;
  }

  .trusted-brands-container .brand-wrapper {
    width: 100% !important;
    height: 100px !important;
    background: #ffffff;
    /* background-color: orange !important; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
  }

  .trusted-brands-container .brand-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .brands-swiper-slide {
    padding: 2px !important;
    /* background-color: green; */
    /* gap: 10px !important; */
  }
}

.trusted-brands-section {
  background: #f4f4f4;
  margin-top: 3rem;
  padding-bottom: 60px;
}

.trusted-brand-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}
.trusted-brand-header h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.trusted-brand-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.trusted-brands-container {
  padding-left: 60px;
}

.trusted-brands-container .brand-wrapper {
  width: 160px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.trusted-brands-container .brand-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.brands-swiper-slide {
  padding: 10px;
}

/* FAQ Styles */

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .faq-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;

    padding-bottom: 15px !important;
  }

  .faq-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .faq-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 176px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .faq-container {
    padding: 0rem 20px !important;
    display: flex;
    flex-direction: column;
    gap: 15px !important;
  }

  .faq {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px !important;
  }
  .faq .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .faq .faq-question .faq-question-title {
    display: flex;
  }
  .faq .faq-question h2 {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 15px !important;
    color: #4a4a4a;
    align-self: baseline;
    display: inline-block;
    margin: 0;
  }
  .faq .faq-question h3 {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 15px !important;
    color: #4a4a4a;
    /* padding: 0px 40px; */

    margin-left: 14px;
  }
  .faq .faq-question img {
    width: 15px !important;
    height: 15px !important;
    object-fit: cover;
  }
  .faq .answer p {
    width: 93% !important;
    /* padding: 0px 40px; */
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px !important;
    color: #4a4a4a;
    margin: 0;
    margin-left: 13px !important;
    word-break: break-all;
  }
}

@media screen and (max-width: 767px) {
  .faq-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;

    padding-bottom: 15px !important;
  }

  .faq-header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px !important;
    color: #050f56;
    text-transform: uppercase;
    position: relative;
  }
  .faq-header h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 142px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }

  .faq-container {
    padding: 0rem 10px !important;
    display: flex;
    flex-direction: column;
    gap: 15px !important;
  }

  .faq {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px !important;
  }
  .faq .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .faq .faq-question .faq-question-title {
    display: flex;
  }
  .faq .faq-question h2 {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 11px !important;
    color: #4a4a4a;
    align-self: baseline;
    display: inline-block;
    margin: 0;
  }
  .faq .faq-question h3 {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 11px !important;
    color: #4a4a4a;
    /* padding: 0px 40px; */

    margin-left: 14px;
  }
  .faq .faq-question img {
    width: 10px !important;
    height: 10px !important;
    object-fit: cover;
  }
  .faq .answer p {
    width: 90% !important;
    /* padding: 0px 40px; */
    font-family: "Roboto";
    font-weight: 400;
    font-size: 10px !important;
    color: #4a4a4a;
    margin: 0;
    margin-left: 13px !important;
    word-break: break-all;
  }
}

.faq-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.faq-header h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  color: #050f56;
  text-transform: uppercase;
  position: relative;
}
.faq-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 132px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}

.faq-container {
  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 14px 20px;
}
.faq .faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq .faq-question .faq-question-title {
  display: flex;
}
.faq .faq-question h2 {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;
  align-self: baseline;
  display: inline-block;
  margin: 0;
}
.faq .faq-question h3 {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;
  /* padding: 0px 40px; */
  margin-bottom: 0;
  margin-left: 14px;
}
.faq .faq-question img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.faq .answer p {
  width: 97%;
  /* padding: 0px 40px; */
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  margin: 0;
  margin-left: 16px;
  word-break: break-all;
}

/* Truck section */

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .truck-section {
    padding: 20px !important;
    margin-top: 25px !important;
  }

  .truck-section .image-wrapper {
    border-radius: 5px !important;
    overflow: hidden;
  }
  .truck-section .image-wrapper img {
    width: 100%;
    height: 250px !important;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .truck-section {
    padding: 10px !important;
    margin-top: 15px !important;
  }

  .truck-section .image-wrapper {
    border-radius: 5px !important;
    overflow: hidden;
  }
  .truck-section .image-wrapper img {
    width: 100%;
    height: 10% !important;
    object-fit: cover;
  }
}

.truck-section {
  padding: 0rem 2rem;
  margin-top: 40px;
  width: 100%;
}

.truck-section .image-wrapper {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.truck-section .image-wrapper img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.gd_container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  max-width: 1500px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  /* buyer details container */

  .buyer-dtails-container {
    /* background-color: orange !important; */

    background: #ffffff !important;
    border-radius: 20px !important;
    padding: 20px !important;
  }
  .buyer-dtails-container img {
    width: 8% !important;
    float: right !important;
  }
  .buyer-dtails-container h1 {
    /* margin-top: 30px !important; */
    position: relative;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    color: #050f56;
    /* margin-bottom: 20px !important; */
  }
  .buyer-dtails-container h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 118px !important;
    height: 2px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .buyer-dtails-container input {
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    width: 100% !important;
    padding: 10px !important;
    margin-bottom: 20px !important;
  }
  .buyer-dtails-container input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #b5b5b5;
  }
  .buyer-dtails-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    color: #ffffff;
    margin-top: 10px !important;
    /* margin-bottom: 20px !important; */
    border: none !important;
  }

  /* buyer otp container */
  .buyer-otp-container {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px !important;
  }
  .buyer-otp-container img {
    width: 8% !important;
    float: right !important;
  }
  .buyer-otp-container .instruction-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    color: #7e7e7e;
    margin-top: 30px !important;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input {
    position: relative !important;
    border: none;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
  }
  .buyer-phone-number-input input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: rgba(78, 78, 78, 0.76);
  }
  .buyer-phone-number-input img {
    position: absolute !important;
    right: 5% !important;
    width: 6% !important;
    top: 20%;
  }
  .enter-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    color: #050f56;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
  .new-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #c4c4c4;
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }

  .buyer-otp-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    color: #ffffff;
    border: none !important;
    /* margin-bottom: 20px !important; */
  }
  .otpStyle {
    /* background-color: orangered !important; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    height: 50px !important;
  }
  .otBoxStyle {
    /* background-color: orange !important; */
    width: 75% !important;
    border: 2px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    height: 100% !important;
  }
  /* seller detail container */

  .sellerDetailsContainer {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sellerDetailsContainer img {
    float: right !important;
    margin-left: 95% !important;
    width: 8% !important;
  }
  .sellerDetailsContainer .userProfilePic {
    height: 80px !important;
    width: 80px !important;
    border-radius: 50% !important;
    /* background-color: orange !important; */
    margin: 10px !important;

    border: 2px solid #050f56;
  }
  .sellerDetailsContainer .userProfilePic img {
    height: 100%;
  }

  .sellerDetailsContainer .userName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin-bottom: 0px !important;
  }
  hr {
    width: 350px !important;
    border: 1.5px solid #e1e1e1;
    margin-bottom: 20px !important;
  }

  .sellerDetailsContainer input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
    margin-bottom: 15px !important;
  }
  .sellerDetailsContainer input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #b5b5b5;
  }
}
/* .mob-menue-overlay {
  background-color: rgba(106, 142, 35, 0.315) !important;
  width: 100vw !important;
  height: 100vh !important;
  position: absolute !important;
  top: 0% !important;
  left: 0%;
  z-index: 99;
  overflow-x: hidden;
} */

.no-data-home {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data-home img {
  width: 30%;
  height: 30%;
  object-fit: contain;
}

.floating-btn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  bottom: 20px;
  right: 25px;
  background-color: #ffffff;
  color: #fe620d;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 856745;
  cursor: pointer;
}
.floating-btn .phone-no-text {
  background-color: #ffffff;
  width: 200px;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15%;
  bottom: 75px;
  right: 30px;
  border-radius: 20px;
}
.floating-btn .phone-no-text p {
  color: #fe620d;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin: 0;
  font-weight: bold;
}

.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* @media screen and (max-width: 1000px) {
  .no-data-home {
    width: 100px;
  }
} */
.blue-text {
  color: #050f56;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
