.user_login_contaier{
    background-color: white;
    display: flex;
    justify-content: space-around;
    width:100%;
    height: 45rem;
    transform: translateY(7.2rem);
  }
  .login_register_container{
    width: 30%;
    background: #FFFFFF;
  box-shadow: 0rem 0.06rem 0.36rem 0.12rem rgba(0, 0, 0, 0.11);
  border-radius: 1.2rem;
  height: 25.8rem;
  transform: translateX(15%);
  }
  .login_register_div{
    display: flex;
    justify-content: space-around;
    margin: 0rem;
    border-bottom: 0.06rem solid #D5D5D5;
    margin-bottom:1.8rem ;
    padding:1.8rem ;
    padding-bottom:1.5rem;
  }
  .login_button{
    background: #FFFFFF;
  border: 0.06rem solid rgba(0, 0, 0, 0.25);
  box-shadow: 0rem 0rem 0.24rem rgba(0, 0, 0, 0.25);
  border-radius: 0.24rem;
  padding: 0.6rem;
  width: 40%;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.78rem;
  }
  .register_button{
    
    background: #FFFFFF;
    border: 0.06rem solid rgba(0, 0, 0, 0.25);
  box-shadow: 0rem 0rem 0.24rem rgba(0, 0, 0, 0.25);
  border-radius: 0.24rem;
  padding: 0.6rem;
  width: 40%;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.78rem;
  
  }
  .loign_options_div{
    display: flex;
    width: 90%;
    margin-bottom:1.8rem ;
    
    margin-top:2.4rem ;
  }
  .loign_options_div a{
    text-decoration: none;
    color: black;
    width: 90%;
  }
  .login_logos{
    width: 10%;
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }
  .login_text{
    width: 90%;
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.96rem;
  cursor: pointer;
  }
  
  .login_arrow{
    width: 5%;
    transform: translateX(90%);
  
  }
  /* profile */
  .profile{
    width: 45%;
    height: 46.2rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.06rem 0.36rem 0.12rem rgba(0, 0, 0, 0.11);
    border-radius: 1.2rem;
    transform: translateX(-10%);
  }
  .profile_heading{
    color: #050F56;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    margin: 0.9rem;
  }
  .profile_underline{
    
    border-bottom: 0.3rem solid #FE620D;
    border-radius: 0.3rem;
    
  }
  .profile_initials{
    background: #ECECEC;
  box-shadow: 0rem 0rem 0.24rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 17%;
  height: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  transform: translateX(240%);
  }
  .profile_initials_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.92rem;
    transform: translateX(30%);
  }
  .edit_box{
    width: 18%;
    transform: translate(140%,-130%);
  }
  .edit_pen{
    width: 19%;
    transform: translate(70%,-160%);
  }
  
  .user_name,.user_email,.user_number,.user_subscription{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  color: #050F56;
  transform: translateX(3.3%);
  margin: 0rem;
  }
  .Email_user{
    box-shadow: 0rem 0rem 0.3rem rgba(0, 0, 0, 0.14);
    width: 90%;
    height: 2.4rem;
    padding-left: 1.2rem;
    padding-top: 0.6rem ;
    padding-bottom: 0.6rem;
    margin-bottom: 1.2rem;
    transform: translateX(1.2rem);
    border-radius: 0.3rem;
    border: none;
  }
  
  
  .profile_name_edit{
    position: absolute;
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.96rem;
  color: #00ADEF;
  transform: translate(-65%,65%);
  }
  .user_profile_Name,.My_subscription{
    border: 0.06rem solid #ddd;
      width: 85%;
      height: 2.4rem;
      padding-left: 1.2rem;
      margin-bottom: 1.2rem;
      transform: translateX(1.2rem);
      border-radius: 0.3rem;
  }
  .Save_changes_button{
    
      width: 94%;
      height: 3.6rem;
      padding-left: 1.2rem;
      /* padding-top: 0.6rem; */
      /* padding-bottom:0.6rem ; */
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
      transform: translateX(1.2rem);
    background-color: #050F56;
    color: white;
    text-align: center;
    font-size: larger;
    font-weight: 500;
    border-radius: 0.3rem;
    border: none;
  }