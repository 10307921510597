.dialog-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1rem;
  border-radius: 1rem;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  z-index: 6852369;
}

.dialog-container .gaddi-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0.7rem;
  border-radius: 1rem;
  position: relative;
  top: -3.2rem;
}

.dialog-container .gaddi-logo img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.dialog-container h3 {
  text-align: center;
  position: relative;
  top: -1.4rem;
  font-size: 1.2rem;
  margin: 0;
}

.dialog-container span {
  display: inline-block;
  font-weight: bold;
  color: #050f56;
}

.dialog-container button {
  width: 100%;
  padding: 0.5rem;
  background-color: #050f56;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 1rem;
  border-radius: 0.5rem;
}
.dialog-container .arrow-btn {
  display: inline-block;
  color: white;
  font-size: 1.5rem;
}

.dialog-container .cancel-btn-img {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.overlay-dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 5266;
}
