@media screen and (max-width: 1000px) {
  .left-profile-container {
    display: none !important;
  }

  .right-profile-container {
    padding: 15px !important;
    width: 100% !important;
    position: relative !important;
  }
  .back-to-home-screen {
    display: inline-block !important;
    transform: rotate(180deg);
    height: 2.5%;
    position: absolute;
    top: 3.5%;
  }
  .user-profile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px !important;
  }
  .user-profile-header h1 {
    padding-top: 0% !important;
    margin-top: -10px !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    margin-bottom: 30px;
    position: relative;
    display: none !important;
  }
  .user-profile-header h2 {
    display: inline-block !important;
    padding-top: 0% !important;
    margin-top: -10px !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    margin-bottom: 20px !important;
    position: relative;
  }
  .user-profile-header h2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 55px !important;
    height: 3px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .profile-pic-div {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .profile-pic-circle {
    background: #ececec;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    height: 5rem !important;
    width: 5rem !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userProfileImg {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .profile-initials {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #000000;
  }
  .edit-img {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 5px !important;
    left: 52.5% !important;
  }
  .name-lable {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 0px !important;
    color: #050f56;
    margin-bottom: 20px !important;
  }
  .user-name-input-div {
    width: 100%;
    /* height: 3.5rem !important; */
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* margin-bottom: 30px !important; */
  }
  .user-name-right-input {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px !important;
    -webkit-appearance: none;
  }
  .user-name-edit {
    position: absolute;
    right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    color: #00adef;
    cursor: pointer;
  }

  .email-lable {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 0px !important;
    color: #050f56;
    margin-bottom: 20px !important;
  }
  .user-email-input-div {
    width: 100%;
    /* height: 3.5rem !important; */
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* margin-bottom: 30px !important; */
  }
  .user-email-input {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px !important;
    -webkit-appearance: none;
  }
  .user-email-edit {
    position: absolute;
    right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    color: #00adef;
    cursor: pointer;
  }

  .mobile-lable {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 0px !important;
    color: #050f56;
    margin-bottom: 20px !important;
  }
  .user-mobile-input-div {
    width: 100%;
    /* height: 3.5rem !important; */
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* margin-bottom: 40px !important; */
  }
  .user-mobile-input {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px !important;
    -webkit-appearance: none;
  }
  .user-mobile-edit {
    position: absolute;
    right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    color: #00adef;
    cursor: pointer;
  }

  .subs-lable {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #050f56;
    margin-bottom: 2%;
  }
  .user-subs-input-div {
    width: 100%;
    /* height: 4rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* margin-bottom: 8%; */
  }
  .user-subs-input {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 100%;
  }
  .user-subs-edit {
    position: absolute;
    right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #00adef;
    cursor: pointer;
  }

  .save-changes-button {
    border: none;
    background: #050f56;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    color: #ffffff;
    /* height: 3.5rem !important; */
    width: 100%;
    /* margin-bottom: 20px !important; */
  }
  .input_img {
    position: absolute;
    top: 1rem;
    right: 13.2rem;
    background-color: #00adef;
    width: 0.7rem;
    height: 0.7rem;
    z-index: 99999;
  }
}
.outside-container {
  display: flex;
  justify-content: center;
}
.profile-container {
  margin: 20px;
  /* width: 75%; */
  display: flex;
  /* background-color: red; */
  justify-content: space-between;
}
.left-profile-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  width: 25%;
  height: fit-content;
  /* height: auto; */
}
.upper-div {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dfdfdf;
  padding: 25px;
}
.hello-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #989898;
  margin-bottom: 0;
}
.user-name-left-div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  color: #1b1b1b;
  margin: 0;
  margin-bottom: -10px;
}
.options-div {
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.my-vehicle-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.shipping-img {
  width: 1.9rem;
  height: 1.9rem;
}
.my-vehicle-text {
  width: 75%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;

  /* background-color: red; */
}
.next-arrow-img {
  width: 1.3rem;
  height: 1.3rem;
}
.my-order-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.clipboard-img {
  width: 1.9rem;
  height: 1.9rem;
}
.my-order-text {
  width: 75%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;
}
.user-Faq-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.help-img {
  width: 1.9rem;
  height: 1.9rem;
}
.user-Faq-text {
  width: 75%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;
}
.sign-out-div {
  /* background-color: #818ace; */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.logout-img {
  width: 1.9rem;
  height: 1.9rem;
}
.sign-out-text {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;
  /* background-color: #fe620d; */
  /* padding-left: 1rem; */
  align-self: flex-start;
  display: block;
}
/* right side contaner */
.back-to-home-screen {
  display: none;
}
.right-profile-container {
  background: #ffffff;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  width: calc(75% - 15px);
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.user-profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile-header h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #050f56;
  margin-bottom: 30px;
  position: relative;
}
.user-profile-header h2 {
  display: none;
}
.user-profile-header h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 65px;
  height: 2px;
  border-radius: 5px;
  background-color: #fe620d;
}
.profile-pic-div {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.profile-pic-circle {
  background: #ececec;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfileImg {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profile-initials {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #000000;
}
.edit-img {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 10px;
  left: 52%;
}
.name-lable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #050f56;
  margin-bottom: 10px;
}
.user-name-input-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.user-name-right-input {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  padding: 10px;
  width: 100%;
  height: 100%;
}
.user-name-right-input:focus {
  outline: none;
}
.user-name-right-input-active {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  padding: 10px;
  width: 100%;
  height: 100%;
  outline: 1px solid #00adef;
}
/* .user-name-right-input-active:focus{
  outline: none;
} */
.user-name-edit {
  position: absolute;
  right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #00adef;
  cursor: pointer;
}

.email-lable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #050f56;
  margin-bottom: 10px;
}
.user-email-input-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.user-email-input {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.user-email-edit {
  position: absolute;
  right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #00adef;
  cursor: pointer;
}

.mobile-lable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #050f56;
  margin-bottom: 10px;
}
.user-mobile-input-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.user-mobile-input {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.user-mobile-edit {
  position: absolute;
  right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #00adef;
  cursor: pointer;
}

.subs-lable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #050f56;
  margin-bottom: 10px;
}
.user-subs-input-div {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.user-subs-input {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 100%;
}
.user-subs-edit {
  position: absolute;
  right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #00adef;
  cursor: pointer;
}

.save-changes-button {
  border: none;
  background: #050f56;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  height: 40px;
  width: 100%;
}
.input_img {
  position: absolute;
  top: 1rem;
  right: 13.2rem;
  background-color: #00adef;
  width: 0.7rem;
  height: 0.7rem;
  z-index: 99999;
}
.hidden {
  visibility: hidden;
}
