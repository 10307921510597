.models-container {
  margin-top: 20px;
}

.models-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.models-fields-container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px 20px;
}

.model-input {
  position: relative;
}
.model-input input {
  width: 100%;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 15px 40px;
}
.model-input input:focus {
  outline: none;
}
.model-input img {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
}

.model-list {
  margin-top: 20px;
  overflow-y: scroll;
  height: 45vh;
}

.model-text {
  cursor: pointer;
}

.model-text p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #696969;
  margin: 10px 0px;
}
.model-text p:hover {
  color: #050f56;
}

.model-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
.model-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.model-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

@media screen and (max-width: 1000px) {
  .model-input input {
    -webkit-appearance: none;
  }
  .model-input input::placeholder {
    font-size: 16px;
  }

  .model-btns button {
    width: 100%;
  }
}
