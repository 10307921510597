.brands-container {
  margin-top: 20px;
}

.brands-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.brands-fields-container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px 20px;
}

.brand-input {
  position: relative;
}
.brand-input input {
  width: 100%;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 15px 40px;
  -webkit-appearance: none;
}
.brand-input input:focus {
  outline: none;
}
.brand-input img {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
}

.brand-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 6px;
  padding: 10px 20px;
  margin-top: 20px;
  overflow-y: scroll;
  height: 45vh;
}

.brand-ui-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.brand-ui-container .brand-img {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
}

.brand-ui-container .brand-img img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.brand-ui-container p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #383838;
  margin: 0;
  margin-top: 5px;
}

@media screen and (max-width: 1000px) {
  .brand-input input {
    -webkit-appearance: none;
  }
  .brand-input input::placeholder {
    font-size: 16px;
  }

  .brand-ui-container .brand-img {
    padding: 5px 5px;
  }

  .brand-ui-container .brand-img img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
}
