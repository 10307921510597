.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found-container img {
  width: 800px;
  height: 800px;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .not-found-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
