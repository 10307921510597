.stepper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.step-divider {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 2px;
  background-color: #050f56;
  z-index: -420;
}

.step-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.step-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.step-circle.active {
  background-color: #00adef;
}

.step-container p {
  margin: 0;
  margin-top: 0.2rem;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #7e7e7e;
}

@media screen and (max-width: 1000px) {
  .step-divider {
    top: 15px;
  }
  .step-circle {
    width: 25px;
    height: 25px;
  }
  .step-container p {
    font-size: 8px;
  }
}
