.body-type-container {
  margin-top: 20px;
}

.body-type-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.body-fields-container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 20px;
}

.body-type-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}

.body-type-list .body-data {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 10px;
  cursor: pointer;
  width: 30%;
  transition: none;
  border: 1px solid transparent;
}
.body-type-list .body-data.active {
  /* border: 1px solid #00adef; */
  background-color: #00adef;
}

.body-type-list .body-data p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
  color: #696969;
  margin: 0;
}

.body-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 10px;
}
.body-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.body-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

@media screen and (max-width: 1000px) {
  .body-type-list {
    column-gap: 0px;
  }
  .body-type-list .body-data {
    width: 45%;
  }
  .body-type-list .body-data p {
    font-size: 12px;
    text-align: center;
  }
  .body-btns button {
    width: 100%;
  }
}
