.detail-pages-navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
  margin: 5px 0;
}
.detail-pages-navigation small {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #414141;
}
@media screen and (max-width: 1000px) {
  .vehicle-details-container {
    padding: 0px !important;
    /* background-color: orange; */
  }

  .vehicle-details-container .vehicle-detail {
    display: block !important;
    gap: 20px;
    /* background-color: #fe620d; */
  }

  .vehicle-details-container .vehicle-detail .vehicle-thumbnail {
    flex: 1 0 65%;
    width: 100% !important;
    height: 80% !important;
    overflow: hidden;
    display: none !important;
    /* padding: -10px !important; */
  }

  .vehicle-details-container .vehicle-detail .mob-vehicle-thumbnail {
    display: block !important;
  }

  .vehicle-details-container .vehicle-detail .mob-vehicle-thumbnail img {
    width: 100%;
    height: 250px !important;
    object-fit: cover !important;
    border-radius: 0px !important;
  }

  .vehicle-details-container .vehicle-detail .vehicle-thumbnail img {
    width: 100%;
    height: 250px !important;
    object-fit: cover !important;
    border-radius: 0px !important;
  }

  .vehicles-swiper-container .swiper-button-prev {
    background: #ffffff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
  }
  .vehicles-swiper-container .swiper-button-prev::after {
    font-size: 20px !important;
    font-weight: bolder;
  }

  .vehicles-swiper-container .swiper-button-next {
    background: #ffffff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
  }
  .vehicles-swiper-container .swiper-button-next::after {
    font-size: 20px !important;
    font-weight: bolder;
  }

  .vehicle-details-container .vehicle-detail .vehicle-info {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100%;
    margin: 12px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-info-wrapper {
    padding: 15px !important;
  }

  .vehicle-details-container .vehicle-detail .vehicle-info .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .wrapper {
    display: flex;
    align-self: baseline;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .wrapper
    .share {
    margin-left: 20px;
    position: relative;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .title
    h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    color: #050f56;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .title
    .truck-location {
    display: flex;
    align-items: center;
    margin-top: 4px !important;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .title
    .truck-location
    img {
    height: 15px !important;
    width: 15px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .title
    .truck-location
    span {
    display: inline-block;
    margin-left: 3px !important;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px !important;
    color: #121212;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .calculator {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px !important;
    cursor: pointer;
    width: 100px !important;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .calculator
    span {
    display: inline-block;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 7px !important;
    color: #000000;
    margin-left: 2px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .share {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .heading-container
    .share
    img {
    width: 18px;
    height: 18px;
  }

  .vehicle-details-container .vehicle-detail .vehicle-info .line {
    width: 100%;
    height: 0.1px;
    background-color: #d4d4d4;
    margin-top: 20px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container {
    margin-top: 15px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-one {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-one
    .stat {
    display: flex;
    align-items: center;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-one
    .stat
    img {
    /* width: 20px !important; */
    height: 25px !important;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat
    img {
    /* width: 20px !important; */
    height: 25px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-one
    .stat.calender {
    margin-right: 12px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-one
    .stat
    span {
    display: inline-block;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-left: 10px;
    text-transform: capitalize;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat {
    display: flex;
    align-items: center;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat.racing {
    margin-left: 19px;
    margin-right: 0;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat.manual {
    height: 2rem;
  }
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat.manual
    img {
    height: 1.5rem;
    width: 2rem;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .vehicle-statistics-container
    .row-two
    .stat
    span {
    display: inline-block;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-left: 10px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-price-container {
    margin-top: 15px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-price-container
    p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    margin-bottom: 0px !important;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-price-container
    p
    span {
    font-size: 25px !important;
    display: inline-block;
    font-weight: bolder;
    color: #00adef;
    margin-left: 10px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container {
    padding: 20px;
    background: #eaf9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    button {
    border: none;
    background: #050f56;
    border-radius: 7px;
    width: 100%;
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 20px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .seller-transaction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    width: 200px;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .seller-transaction
    span {
    display: inline-block;
    color: #000000;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .seller-transaction
    p {
    font-size: 10px;
    color: #777777;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .para {
    font-size: 10px;
    color: #777777;
    text-align: center;
    margin-top: 10px;
  }

  .mob-vehicle-gallery-container {
    /* display: flex !important;
    align-items: center;
    gap: 20px !important; */
    display: block !important;
    margin-top: 5px !important;
    padding: 10px !important;
  }
  .vehicle-details-container .vehicle-gallery-container {
    display: none !important;
    align-items: center;
    gap: 20px;
    margin-top: 0px !important;
  }
  .mob-vehicle-gallery-container .thumb {
    width: 100%;
    height: 60px !important;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  .mob-vehicle-gallery-container .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* padding: 10px !important; */
    /* margin: 10px !important; */
  }

  .vehicles-thumbs-swiper .swiper-button-prev {
    background: #ffffff;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    margin-top: -10px !important;
    margin-left: -20px !important;
  }
  .vehicles-thumbs-swiper .swiper-button-prev::after {
    font-size: 10px !important;
    font-weight: bolder;
  }
  .vehicles-thumbs-swiper .swiper-button-next {
    background: #ffffff;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    margin-top: -10px !important;
    margin-right: -20px !important;
  }
  .vehicles-thumbs-swiper .swiper-button-next::after {
    font-size: 10px !important;
    font-weight: bolder;
  }

  .vehicles-thumbs-swiper .swiper-slide-thumb-active {
    border-color: #00adef;
  }

  /* Overview Styles */
  .overview-container {
    display: none !important;
    padding: 20px;
    /* margin-top: 30px !important; */
    margin: 10px !important;
    /* background-color: orange !important; */
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
  }
  .mob-overview-container {
    display: block !important;
    padding: 20px;
    /* margin-top: 30px !important; */
    margin: 10px !important;
    /* background-color: orange !important; */
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
  }

  .mob-overview-container h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 18px !important;
    color: #050f56;
    position: relative;
  }
  .mob-overview-container h3:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 55px !important;
    height: 3px;
    background-color: #fe620d;
  }

  .mob-overview-container .row-one {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .mob-overview-container .row-one .row-content {
    width: 45% !important;
    height: auto;
    /* background-color: #fe620d; */
  }

  .mob-overview-container .row-one .row-content h6 {
    font-family: "Poppins";
    font-weight: bold;
    color: #050f56;
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .mob-overview-container .row-one .row-content span {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px !important;
    color: #000000;
  }

  .mob-overview-container .line {
    width: 100%;
    height: 0.1px;
    background-color: #d4d4d4;
    margin-top: 20px;
  }

  .mob-overview-container .row-two {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 0;
  }

  .mob-overview-container .row-two .row-content {
    width: 45% !important;
    height: auto;
  }

  .mob-overview-container .row-two .row-content h6 {
    font-family: "Poppins";
    font-weight: bold;
    color: #050f56;
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .mob-overview-container .row-two .row-content span {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px !important;
    color: #000000;
  }

  .mob-overview-container .row-three {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 0;
  }

  .mob-overview-container .row-three .row-content {
    width: 45% !important;
    height: auto;
  }

  .mob-overview-container .row-three .row-content h6 {
    font-family: "Poppins";
    font-weight: bold;
    color: #050f56;
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .mob-overview-container .row-three .row-content span {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px !important;
    color: #000000;
  }

  .mob-overview-container .row-four {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .mob-overview-container .row-four .row-content {
    width: 45% !important;
    height: auto;
  }

  .mob-overview-container .row-four .row-content h6 {
    font-family: "Poppins";
    font-weight: bold;
    color: #050f56;
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .mob-overview-container .row-four .row-content span {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px !important;
    color: #000000;
  }

  /* Similar Vehicles Styles */
  .similar-vehicles-container {
    margin-top: 10px !important;
  }
  .similar-vehicles-container .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px !important;
  }

  .similar-vehicles-container .title h1 {
    text-transform: uppercase;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    color: #050f56;
    position: relative;
  }

  .similar-vehicles-container .title h1:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 75px !important;
    height: 3px;
    background-color: #fe620d;
    border-radius: 5px;
  }

  .similar-vehicles-container .similar-vehicles-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px !important;
    /* background-color: #fe620d; */
    margin: 10px !important;
    overflow-x: hidden;
  }

  .similar-vehicles-container .similar-vehicles-list .similar-vehicle-card {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .img-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .img-wrapper
    img {
    width: 100%;
    height: 170px !important;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info {
    padding: 10px 10px;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .title
    h5 {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    color: #050f56;
    font-size: 15px !important;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .title
    .location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .title
    .location
    img {
    width: 10px !important;
    height: 10px !important;
    object-fit: cover;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .title
    .location
    span {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 10px !important;
    color: #121212;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .price {
    margin-top: 10px;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .price
    p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #121212;
    margin-left: 3px;
    /* background-color: #00adef; */
    display: flex;
    flex-direction: column;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .price
    p
    span {
    display: inline-block;
    font-weight: bold;
    color: #fe620d;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    .gallery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .similar-vehicles-container
    .similar-vehicles-list
    .similar-vehicle-card
    .vehicle-info
    button {
    font-family: "Poppins";
    border: none;
    background: #050f56;
    border-radius: 6px;
    width: 100%;
    font-size: 10px !important;
    padding: 8px 10px !important;
    color: #ffffff;
  }

  /* buyer details container */

  .buyer-dtails-container {
    /* background-color: orange !important; */

    background: #ffffff !important;
    border-radius: 20px !important;
    padding: 20px !important;
  }
  .buyer-dtails-container img {
    /* width: 8% !important; */
    height: 100% !important;
    float: right !important;
    cursor: pointer;
  }
  .buyer-dtails-container h1 {
    /* margin-top: 30px !important; */
    position: relative;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    color: #050f56;
    margin-bottom: 20px !important;
  }
  .buyer-dtails-container h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 118px !important;
    height: 2px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .buyer-dtails-container input {
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    width: 100% !important;
    padding: 10px !important;
    margin-bottom: 20px !important;
  }
  .buyer-dtails-container input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #b5b5b5;
  }
  .buyer-dtails-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    color: #ffffff;
    margin-top: 10px !important;
    /* margin-bottom: 20px !important; */
    border: none !important;
  }

  /* buyer otp container */
  .buyer-otp-container {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px !important;
  }
  .buyer-otp-container img {
    width: 8% !important;
    float: right !important;
    cursor: pointer;
  }
  .buyer-otp-container .instruction-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    color: #7e7e7e;
    margin-top: 30px !important;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input {
    position: relative !important;
    border: none;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
  }
  .buyer-phone-number-input input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: rgba(78, 78, 78, 0.76);
  }
  .buyer-phone-number-input img {
    position: absolute !important;
    right: 5% !important;
    width: 6% !important;
    top: 20%;
  }
  .enter-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    color: #050f56;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
  .new-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #c4c4c4;
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }

  .buyer-otp-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    color: #ffffff;
    border: none !important;
    /* margin-bottom: 20px !important; */
  }
  .otpStyle {
    /* background-color: orangered !important; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    height: 50px !important;
  }
  .otBoxStyle {
    /* background-color: orange !important; */
    width: 75% !important;
    border: 2px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    height: 100% !important;
  }
  /* seller detail container */

  .sellerDetailsContainer {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sellerDetailsContainer img {
    float: right !important;
    margin-left: 95% !important;
    width: 8% !important;
  }
  .sellerDetailsContainer .userProfilePic {
    height: 80px !important;
    width: 80px !important;
    border-radius: 50% !important;
    /* background-color: orange !important; */
    margin: 10px !important;

    border: 2px solid #050f56;
  }
  .sellerDetailsContainer .userProfilePic img {
    height: 100%;
  }

  .sellerDetailsContainer .userName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin-bottom: 0px !important;
  }
  hr {
    width: 350px !important;
    border: 1.5px solid #e1e1e1;
    margin-bottom: 20px !important;
  }

  .sellerDetailsContainer input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
    margin-bottom: 15px !important;
  }
  .sellerDetailsContainer input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #b5b5b5;
  }
}

@media screen and (max-width: 1100px) {
  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .agree-terms {
    display: flex;
    align-items: center;
    margin-top: 20px;

    /* margin-bottom: 10px; */
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .agree-terms
    span {
    margin-left: 5px;
    display: inline-block;
    font-family: "Roboto";
    font-size: 12px !important;
    color: #9b9b9b;
  }

  .vehicle-details-container
    .vehicle-detail
    .vehicle-info
    .selling-detail-container
    .agree-terms
    strong {
    color: #050f56;
  }
}

.mob-overview-container {
  display: none;
}

.mob-vehicle-gallery-container {
  display: none;
}

.vehicle-details-container {
  padding: 10px;
}

.vehicle-details-container .vehicle-detail {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.vehicle-details-container .vehicle-detail .vehicle-thumbnail {
  flex: 1 0 65%;
  overflow: hidden;
}

.vehicle-details-container .vehicle-detail .mob-vehicle-thumbnail {
  display: none;
}

.vehicle-details-container .vehicle-detail .vehicle-thumbnail img {
  width: 100%;
  height: 537px;
  object-fit: cover;
  border-radius: 10px;
}

.vehicles-swiper-container .swiper-button-prev {
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.vehicles-swiper-container .swiper-button-prev::after {
  font-size: 30px;
  font-weight: bolder;
}

.vehicles-swiper-container .swiper-button-next {
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.vehicles-swiper-container .swiper-button-next::after {
  font-size: 30px;
  font-weight: bolder;
}

.vehicle-details-container .vehicle-detail .vehicle-info {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: fit-content;
}

.vehicle-details-container .vehicle-detail .vehicle-info .vehicle-info-wrapper {
  padding: 20px;
}

.vehicle-details-container .vehicle-detail .vehicle-info .heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .wrapper {
  display: flex;
  /* align-self: baseline; */
  flex-direction: column;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .wrapper
  .detail-uid {
  border: 0.1rem solid white;
  padding: 0.5rem 1rem;
  background-color: #00adef;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .wrapper
  .detail-uid
  span {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .wrapper
  .another-wrapper {
  display: flex;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .wrapper
  .share {
  margin-left: 20px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .title
  h3 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #050f56;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .title
  .truck-location {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .title
  .truck-location
  span {
  display: inline-block;
  margin-left: 10px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  color: #121212;
  text-transform: capitalize !important;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .calculator {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .calculator
  span {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 8px;
  color: #000000;
  margin-left: 5px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .share {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .heading-container
  .share
  img {
  width: 18px;
  height: 18px;
}

.vehicle-details-container .vehicle-detail .vehicle-info .line {
  width: 100%;
  height: 0.1px;
  background-color: #d4d4d4;
  margin-top: 20px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container {
  margin-top: 10px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-one-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-one-detail
  .stat {
  display: flex;
  align-items: center;
  width: 100px;
  text-transform: capitalize;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-one-detail
  .stat
  span {
  display: inline-block;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-one-detail
  .stat
  img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-two-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-two-detail
  .stat {
  display: flex;
  align-items: center;
  width: 100px;
  text-transform: capitalize;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-two-detail
  .stat
  span {
  display: inline-block;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}
.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .vehicle-statistics-container
  .row-two-detail
  .stat
  img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-price-container {
  margin-top: 20px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-price-container
  p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-price-container
  p
  span {
  font-size: 30px;
  display: inline-block;
  font-weight: bolder;
  color: #00adef;
  margin-left: 10px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container {
  padding: 20px;
  background: #eaf9ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  button {
  border: none;
  background: #050f56;
  border-radius: 7px;
  width: 100%;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding: 7px 20px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .seller-transaction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;
  width: 200px;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .seller-transaction
  span {
  display: inline-block;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .seller-transaction
  p {
  font-size: 12px;
  color: #777777;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .agree-terms {
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .agree-terms
  span {
  margin-left: 5px;
  display: inline-block;
  font-family: "Roboto";
  font-size: 10px;
  text-align: center;
  color: #9b9b9b;
}

.vehicle-details-container
  .vehicle-detail
  .vehicle-info
  .selling-detail-container
  .agree-terms
  strong {
  color: #050f56;
}

.vehicle-details-container .vehicle-gallery-container {
  /* display: flex;
  align-items: center;
  gap: 20px; */
  margin-top: 20px;
}
.vehicle-details-container .vehicle-gallery-container .thumb {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.vehicle-details-container .vehicle-gallery-container .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vehicles-thumbs-swiper .swiper-button-prev {
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.vehicles-thumbs-swiper .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bolder;
}
.vehicles-thumbs-swiper .swiper-button-next {
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.vehicles-thumbs-swiper .swiper-button-next::after {
  font-size: 20px;
  font-weight: bolder;
}

.vehicles-thumbs-swiper .swiper-slide-thumb-active {
  border: 2px solid #00adef;
  border-radius: 10px;
}

/* Overview Styles */
.overview-container {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 20px;
}

.overview-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #050f56;
  position: relative;
}
.overview-container h3:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 3px;
  background-color: #fe620d;
}

.overview-container .row-one {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.overview-container .row-one .row-content {
  width: 200px;
  height: auto;
}

.overview-container .row-one .row-content h6 {
  font-family: "Poppins";
  font-weight: bold;
  color: #050f56;
}
.overview-container .row-one .row-content span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}

.overview-container .line {
  width: 100%;
  height: 0.1px;
  background-color: #d4d4d4;
  margin-top: 20px;
}

.overview-container .row-two {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 0;
}

.overview-container .row-two .row-content {
  width: 200px;
  height: auto;
}

.overview-container .row-two .row-content h6 {
  font-family: "Poppins";
  font-weight: bold;
  color: #050f56;
}
.overview-container .row-two .row-content span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}

.overview-container .row-three {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 0;
}

.overview-container .row-three .row-content {
  width: 200px;
  height: auto;
}

.overview-container .row-three .row-content h6 {
  font-family: "Poppins";
  font-weight: bold;
  color: #050f56;
}
.overview-container .row-three .row-content span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}
.overview-container .row-three .row-content.regNo span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
}

.overview-container .row-four {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.overview-container .row-four .row-content {
  width: 200px;
  height: auto;
}

.overview-container .row-four .row-content h6 {
  font-family: "Poppins";
  font-weight: bold;
  color: #050f56;
}
.overview-container .row-four .row-content span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}

/* Similar Vehicles Styles */
.similar-vehicles-container {
  margin-top: 20px;
}
.similar-vehicles-container .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.similar-vehicles-container .title h1 {
  text-transform: uppercase;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: #050f56;
  position: relative;
}

.similar-vehicles-container .title h1:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 132px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
}

.similar-vehicles-container .similar-vehicles-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.similar-vehicles-container .similar-vehicles-list .similar-vehicle-card {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .similar-uid {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: #00adef;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .similar-uid
  span {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}
.similar-vehicles-container .similar-vehicles-list .similar-vehicle-card a {
  text-decoration: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .img-wrapper {
  width: 200px;
  height: 100%;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .img-wrapper
  img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info {
  padding: 10px 10px;
  width: 100%;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .title
  h5 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #050f56;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .title
  .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .title
  .location
  img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .title
  .location
  span {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #121212;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .price {
  margin-top: 10px;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .price
  p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #121212;
  margin-left: 3px;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .price
  p
  span {
  display: inline-block;
  font-weight: bold;
  color: #fe620d;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  .gallery {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.similar-vehicles-container
  .similar-vehicles-list
  .similar-vehicle-card
  .vehicle-info
  button {
  font-family: "Poppins";
  border: none;
  background: #050f56;
  border-radius: 6px;
  width: 100%;
  font-size: 16px;
  padding: 6px 20px;
  color: #ffffff;
}

/* buyer details container */

.buyer-dtails-container {
  /* background-color: orange !important; */
  background: #ffffff !important;
  border-radius: 20px !important;
  padding: 20px !important;
  position: relative;
}

.animation1 {
  animation-name: display;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes display {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.buyer-dtails-container img {
  width: 25px !important;
  height: 25px !important;
  object-fit: cover;
  float: right !important;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5000;
  /* margin-bottom: 90px !important; */
}
.buyer-dtails-container h1 {
  /* margin-top: 50px; */
  position: relative;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  color: #050f56;
  margin-bottom: 20px !important;
}
.buyer-dtails-container h1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 118px !important;
  height: 2px !important;
  border-radius: 5px;
  background-color: #fe620d;
}
.buyer-dtails-container input {
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 20px !important;
}
.buyer-dtails-container input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  color: #b5b5b5;
}

.buyer-dtails-container .buyer-location {
  position: relative;
}
.buyer-dtails-container .buyer-location img {
  position: absolute;
  width: 4% !important;
  opacity: 0.3;
  right: 2%;
  top: 20%;
}
.buyer-dtails-container .dealerType {
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.buyer-dtails-container .dealerType .dealer-que {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00adef;
  margin-bottom: 10px;
}

.dealerTypeOption {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.dealer {
  /* width: 28% !important; */
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap !important;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  transition: none;
  border: 1px solid transparent;
  font-size: 10px !important;
}
.dealer.active {
  /* border: 1px solid #00adef; */
  background-color: #00adef;
}

.buyer-dtails-container .dealerType button {
  width: 22.5% !important;
  background: #ffffff;
  border: 1px solid #00aeef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  text-align: center;
  padding: 5px 0px !important;
  color: #626262;
  margin-right: 10px;
}

.buyer-dtails-container button {
  width: 100% !important;
  padding: 10px !important;
  background: #050f56;
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  color: #ffffff;
  margin-top: 10px !important;
  /* margin-bottom: 20px !important; */
  border: none !important;
}

.modal-agree-terms {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-agree-terms input {
  margin-bottom: 0 !important;
}
.modal-agree-terms span {
  display: inline-block;
  font-family: "Roboto";
  font-size: 10px;
  text-align: center;
  color: #9b9b9b;
}

/* buyer otp container */
.buyer-otp-container {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px !important;
}
.buyer-otp-container img {
  width: 8% !important;
  float: right !important;
  cursor: pointer;
}
.buyer-otp-container .instruction-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  color: #7e7e7e;
  margin-top: 30px !important;
  margin-bottom: 15px !important;
}
.buyer-phone-number-input {
  position: relative !important;
  border: none;
  margin-bottom: 15px !important;
}
.buyer-phone-number-input input {
  width: 100% !important;
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  padding: 10px !important;
}
.buyer-phone-number-input input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: rgba(78, 78, 78, 0.76);
}
.buyer-phone-number-input img {
  position: absolute !important;
  right: 5% !important;
  width: 6% !important;
  top: 20%;
  cursor: pointer;
}
.enter-otp-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  color: #050f56;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
}
.new-otp-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #c4c4c4;
  text-align: center !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  cursor: pointer;
}

.buyer-otp-container button {
  width: 100% !important;
  padding: 10px !important;
  background: #050f56;
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  color: #ffffff;
  border: none !important;
  /* margin-bottom: 20px !important; */
}
.otpStyle {
  /* background-color: orangered !important; */
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  height: 50px !important;
}
.otBoxStyle {
  /* background-color: orange !important; */
  width: 75% !important;
  border: 2px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  height: 100% !important;
}
/* seller detail container */

.sellerDetailsContainer {
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  justify-content: center !important;
  align-items: center !important;
}
.sellerDetailsContainer img {
  float: right !important;
  margin-left: 95% !important;
  width: 8% !important;
  cursor: pointer;
}
.sellerDetailsContainer .userProfilePic {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
  /* background-color: orange !important; */
  margin: 10px !important;

  border: 2px solid #050f56;
}

.sellerDetailsContainer .userProfilePic img {
  height: 100%;
  width: 100% !important;
  border-radius: 50%;
}
.sellerDetailsContainer .userName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0px !important;
}
hr {
  width: 350px !important;
  border: 1.5px solid #e1e1e1;
  margin-bottom: 20px !important;
}

.sellerDetailsContainer input {
  width: 100% !important;
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  padding: 10px !important;
  margin-bottom: 15px !important;
}
.sellerDetailsContainer input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #b5b5b5;
}

/* Emi section */
.emi-section {
  margin: 10px;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 70vw;
  height: 100%;
}

/* .verticle-hr{
  background-color: #00adef;
  width: 50% !important;
  transform: rotate(90deg) !important;
}  */
.emi-left-side-div {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #b1b1b1;
  /* background-color: orange; */
  width: 50%;
}
.emi-left-top-div {
  padding: 10px;
  border-bottom: 1px solid #b1b1b1;
  margin-top: 50px;
  text-align: center;
  color: #050f56;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.emi-left-top-div .selling-price {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  /* line-height: 59px; */
  color: #00adef;
}
.emi-left-mid-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
   */
  /* align-items: flex-start; */
}
.donut-graph {
  /* background-color: #00ADEF; */
  width: 40%;
  margin-bottom: 50px;
  /* height: 290px; */
  align-self: center !important;
  /* justify-self: center !important; */
}
.donut-cotainer {
  /* background-color: red; */
  font-size: 30px !important;
  height: 300px !important;
  width: 300px !important;
}
.cir1 {
  background-color: #caf0ff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 5px;
}
.cir2 {
  background-color: #050f56;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 5px;
}
.pla-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pla-text {
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #5f5f5f;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pla-price {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #00adef;
}
.emi-left-bottom-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  /* background-color: orangered; */
  border-top: 1px solid #b1b1b1;
}

.Tap-img-text {
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* background-color: orange; */
}
.emi-left-bottom-div img {
  width: 6%;
  /* height: 20px; */
  margin-right: 5px;
}
.emi-right-side-div {
  padding: 20px;
  width: 50%;
  /* padding-bottom: 0px; */
  /* display: flex; */
  /* flex-direction: column; */
}
.emi-right-side-div img {
  width: 20px;
  height: 20px;
  position: relative;
  float: right;
  cursor: pointer;
}
.la-div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.la-top-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.selected-la {
  align-self: center;
  width: 33%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  border: 2px solid #050f56;
  /* border-radius: 5px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.ir-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0px;
}
.ir-number-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  /* background-color: #00ADEF; */
}
.percent-symbol {
  color: #00adef;
  /* text-align: center !important; */
  /* background-color: #050f56; */
  padding-right: 10px;
  border-right: 1px solid #c0c0c0 !important;
}
.ir-percent {
  /* background-color: red !important; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00adef;
  width: 50px;
  border: none;
  padding: 8px;
  background-color: transparent;
  text-align: right;
  padding-right: 5px !important;
}

.ir-percent:focus {
  outline: none;
}
.ir-number-div img {
  /* background-color: #00adef; */
  width: 15px;
  height: 15px;
  padding: 0px;
  margin: 8px;
}
.emi-right-side-div button {
  width: 100%;
  background: #050f56;
  border: 1px solid rgba(5, 15, 86, 0.1);
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 0px;
  margin-top: 10px;
}
.min-max {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #909090;
}
.cross-img-mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .buyer-dtails-container {
    /* background-color: orange !important; */
    background: #ffffff !important;
    border-radius: 20px !important;
    padding: 20px !important;
  }
  .buyer-dtails-container img {
    width: 25px !important;
    float: right !important;
    /* margin-bottom: 90px !important; */
  }
  .buyer-dtails-container h1 {
    /* margin-top: 50px; */
    position: relative;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    color: #050f56;
    margin-bottom: 20px !important;
  }
  .buyer-dtails-container h1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 118px !important;
    height: 2px !important;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .buyer-dtails-container input {
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    width: 100% !important;
    padding: 10px !important;
    margin-bottom: 20px !important;
  }
  .buyer-dtails-container input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #b5b5b5;
  }

  .buyer-dtails-container .buyer-location {
    position: relative;
  }
  .buyer-dtails-container .buyer-location img {
    position: absolute;
    width: 4% !important;
    opacity: 0.3;
    right: 2%;
    top: 20%;
  }
  .buyer-dtails-container .dealerType {
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .buyer-dtails-container .dealerType .dealer-que {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00adef;
    margin-bottom: 10px;
  }

  .dealerTypeOption {
    /* background-color: #00adef; */
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    margin-bottom: 5px !important;
    flex-wrap: wrap;
    gap: 10px !important;
    padding: 5px !important;
  }

  .dealer {
    width: 30% !important;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: center;
    padding: 5px 5px !important;
    cursor: pointer;
    transition: none;
    border: 1px solid transparent;
    font-size: 8px !important;
  }
  .dealer.active {
    border: 1px solid #00adef;
  }

  .buyer-dtails-container .dealerType button {
    width: 22.5% !important;
    background: #ffffff;
    border: 1px solid #00aeef;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px !important;
    text-align: center;
    padding: 5px 0px !important;
    color: #626262;
    margin-right: 10px;
  }

  .buyer-dtails-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    color: #ffffff;
    margin-top: 10px !important;
    /* margin-bottom: 20px !important; */
    border: none !important;
  }

  /* buyer otp container */
  .buyer-otp-container {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px !important;
  }
  .buyer-otp-container img {
    width: 8% !important;
    float: right !important;
  }
  .buyer-otp-container .instruction-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    color: #7e7e7e;
    margin-top: 30px !important;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input {
    position: relative !important;
    border: none;
    margin-bottom: 15px !important;
  }
  .buyer-phone-number-input input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
  }
  .buyer-phone-number-input input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: rgba(78, 78, 78, 0.76);
  }
  .buyer-phone-number-input img {
    position: absolute !important;
    right: 5% !important;
    width: 6% !important;
    top: 20%;
  }
  .enter-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    color: #050f56;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
  .new-otp-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #c4c4c4;
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    cursor: pointer;
  }

  .buyer-otp-container button {
    width: 100% !important;
    padding: 10px !important;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    color: #ffffff;
    border: none !important;
    /* margin-bottom: 20px !important; */
  }
  .otpStyle {
    /* background-color: orangered !important; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    height: 50px !important;
  }
  .otBoxStyle {
    /* background-color: orange !important; */
    width: 75% !important;
    border: 2px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    height: 100% !important;
  }
  /* seller detail container */

  .sellerDetailsContainer {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sellerDetailsContainer img {
    float: right !important;
    margin-left: 95% !important;
    width: 8% !important;
  }
  .sellerDetailsContainer .userProfilePic {
    height: 80px !important;
    width: 80px !important;
    border-radius: 50% !important;
    /* background-color: orange !important; */
    margin: 10px !important;

    border: 2px solid #050f56;
  }

  .sellerDetailsContainer .userProfilePic img {
    height: 100%;
    width: 100% !important;
    border-radius: 50%;
  }
  .sellerDetailsContainer .userName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin-bottom: 0px !important;
  }
  hr {
    width: 350px !important;
    border: 1.5px solid #e1e1e1;
    margin-bottom: 20px !important;
  }

  .sellerDetailsContainer input {
    width: 100% !important;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    padding: 10px !important;
    margin-bottom: 15px !important;
  }
  .sellerDetailsContainer input::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #b5b5b5;
  }
}

/* emi section mobile responsive */
@media screen and (max-width: 1000px) {
  .emi-section {
    margin: 10px;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 97%;
  }

  /* .verticle-hr{
    background-color: #00adef;
    width: 50% !important;
    transform: rotate(90deg) !important;
  }  */
  .emi-left-side-div {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-right: none;
    /* background-color: orange; */
    width: 100%;
  }
  .emi-left-top-div {
    padding: 5px;
    border-bottom: 1px solid #b1b1b1;
    margin-top: 30px;
    text-align: center;
    color: #050f56;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
  }
  .emi-left-top-div .selling-price {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 59px; */
    color: #00adef;
  }
  .emi-left-mid-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    /* justify-content: space-between;
     */
    /* align-items: flex-start; */
  }
  .donut-graph {
    /* background-color: #00ADEF; */
    width: 30%;
    margin-bottom: 20px;
    /* height: 290px; */
    align-self: center !important;
    /* justify-self: center !important; */
  }
  .donut-cotainer {
    /* background-color: red; */
    font-size: 30px !important;
    height: 300px !important;
    width: 300px !important;
  }
  .cir1 {
    background-color: #caf0ff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    align-self: center;
    margin-right: 5px;
  }
  .cir2 {
    background-color: #050f56;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    align-self: center;
    margin-right: 5px;
  }
  .pla-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pla-text {
    align-self: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #5f5f5f;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: #00adef; */
  }
  .pla-price {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    color: #00adef;
    /* background-color: olivedrab !important; */
  }
  .emi-left-bottom-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    /* background-color: orangered; */
    border-top: 1px solid #b1b1b1;
    border-bottom: 1px solid #b1b1b1;
  }

  .Tap-img-text {
    color: #050f56;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* background-color: orange; */
  }
  .emi-left-bottom-div img {
    width: 8%;
    /* height: 20px; */
    margin-right: 5px;
  }
  .emi-right-side-div {
    padding: 0px 20px;
    width: 100%;
    /* padding-bottom: 0px; */
    /* display: flex; */
    /* flex-direction: column; */
  }
  .emi-right-side-div img {
    width: 20px;
    height: 20px;
    position: relative;
    float: right;
    cursor: pointer;
    /* top: -90%; */
  }
  .cross-img {
    display: none;
  }
  .cross-img-mobile {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    float: right;
    right: 5%;
    top: 3%;
    cursor: pointer;
  }
  .la-div {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* background-color: #00adef; */
  }
  .la-top-div {
    display: flex;
    flex-direction: row;
    /* background-color: olive; */
    align-items: center;
    justify-content: space-between;
  }
  .la-text {
    font-size: 11px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
  }
  .selected-la {
    align-self: center;
    width: 33%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    border: 2px solid #050f56;
    /* border-radius: 5px; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  }
  .range-slider span {
    padding: 0%;
  }
  .min-max {
    font-size: 10px !important;
    margin: 0% !important;
    padding: 0% !important;
    /* background-color: #00adef; */
  }
  .ir-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    color: #484848;
  }
  .ir-number-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #c0c0c0;
    border-radius: 5px;

    /* background-color: #00ADEF; */
  }

  .percent-symbol {
    color: #00adef;
    /* text-align: center !important; */
    /* background-color: #050f56; */
    padding-right: 10px;
    border-right: 1px solid #c0c0c0 !important;
  }
  .ir-percent {
    /* background-color: red !important; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #00adef;
    width: 50px;
    border: none;
    padding: 8px;
    background-color: transparent;
    text-align: right;
    padding-right: 5px !important;
  }

  .ir-percent:focus {
    outline: none;
  }
  .ir-number-div img {
    /* background-color: #00adef; */
    width: 15px;
    height: 15px;
    padding: 0px;
    margin: 10px;
  }
  .emi-right-side-div button {
    width: 100%;
    background: #050f56;
    border: 1px solid rgba(5, 15, 86, 0.1);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    padding: 10px 0px;
    margin-top: 10px;
  }
  .min-max {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #909090;
  }
}
.share-vehicle-link {
  /* background-color: #00adef; */
  position: absolute;
  top: 9%;
  right: 2%;
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #050f56;
  border-radius: 10px;
  z-index: 99;
}
.hide-share-link-div {
  display: none;
}
.mobile-share-vehicle-link {
  position: absolute;
  top: 100%;
  right: 0%;
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #050f56;
  border-radius: 10px;
  z-index: 99;
}
.full-screen-overlay {
  background-color: transparent;
  /* background-color: rgba(255, 0, 0, 0.219); */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  top: 0%;
}
