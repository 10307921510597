.info-container {
  margin-top: 20px;
}

.info-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.info-fields-container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 20px;
}

.permit-container {
  margin-top: 10px;
}

.permit-container .permit-data-container input {
  border: none;
  width: 100%;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.permit-container .permit-data-container input:focus {
  outline: none;
}

.rc-container h6,
.permit-container h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.rc-data-container,
.permit-data-container {
  margin-top: 5px;
  display: flex;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px 20px;
}
.rc-data-container .rc-data,
.permit-data-container .permit-data {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 10px;
  cursor: pointer;
  width: 30%;
  transition: none;
  border: 1px solid transparent;
}
.rc-data-container .rc-data.active,
.permit-data-container .permit-data.active {
  /* border: 1px solid #00adef; */
  background-color: #00adef;
}
.rc-data-container .rc-data p,
.permit-container .permit-data p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #696969;
  text-align: center;
  margin: 0;
}

.info-input-controls {
  margin: 10px 0 0 0;
}

.info-input-controls h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.info-input-controls input {
  border: none;
  width: 100%;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.info-input-controls input:focus {
  outline: none;
}

.info-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 10px;
}
.info-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.info-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

@media screen and (max-width: 1000px) {
  .info-btns button {
    width: 100%;
  }

  .rc-data-container .rc-data p,
  .permit-container .permit-data p {
    font-size: 12px;
  }

  .info-input-controls input {
    -webkit-appearance: none;
  }
}
