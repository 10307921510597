@media screen and (max-width: 1000px) {
  .mob-menue-overlay {
    background-color: transparent !important;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
  }
  .pages-navigation .sort-btn {
    display: none !important;
  }
  .filter-sort {
    display: none;
  }
  .filter-sort-button-div {
    display: flex !important;
    flex-direction: row;
    gap: 5%;
    /* justify-content: space-between; */
    padding-top: 10px;
    margin: 10px;
    /* background-color: #fe620d; */
    /* width: 100%; */
  }
  .filter-button,
  .sortBy-button {
    position: relative;
  }
  .filter-button img {
    position: absolute;
    z-index: 99;
    width: 15%;
    top: 30%;
    right: 10%;
  }
  .sortBy-button img {
    position: absolute;
    z-index: 99;
    width: 15px;
    height: 15px;
    top: 30%;
    right: 5px;
  }
  .filter-button button {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #050f56;
    padding-left: 10px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sortBy-button button {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #050f56;
  }
  .vehicles-container {
    padding: 10px 10px;
    display: flex;
    margin-top: 0px !important;
    gap: 20px;
    justify-content: center;
  }

  .vehicles-container .filter-sidebar {
    width: 300px;
    display: none !important;
  }

  .filter-sidebar .container {
    /* width: 30% !important; */
    /* background-color: #fe620d !important; */
  }

  .filter-one {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-left: 10px !important;
    width: 200px;
    position: absolute;
    top: 200px !important;
    left: 25%;
    z-index: 99;
  }

  .filter-one .line {
    width: 100%;
    height: 0.1px;
    background-color: #d4d4d4;
  }

  .filter-one .filter-container {
    padding: 10px 20px 0;
  }
  .filter-one .filter-container.kmsdriven {
    padding: 20px 20px 20px;
  }
  .filter-one .filter-container .sort-by {
    display: none;
  }
  .filter-one .filter-container h3 {
    /* display: none; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #050f56;
    position: relative;
  }
  .filter-one .filter-container h3:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 3px;
    background-color: #fe620d;
  }

  .filter-one .filter-container .filter-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .filter-one .filter-container .filter-controls span {
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 14px;
    margin-left: 20px;
  }

  /* Filter Two */
  .filter-two {
    background: #ffffff !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    margin-top: 20px !important;
    width: 70%;
    position: absolute;
    z-index: 99;
    top: 200px !important;
    margin-left: 10px !important;
  }

  .filter-two .search-container {
    display: none !important;
  }
  .filter-two .search-container img {
    position: absolute;
    top: 35px;
    left: 10px;
  }
  .filter-two .search-container input {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 0.5rem 2rem;
    font-family: inherit;
    border: none;
  }
  .filter-two .search-container input:focus {
    outline: none;
  }

  .filter-two .categories-container {
    padding: 20px;
    padding-bottom: 0px !important;
    /* background-color: #00adef ; */
  }
  .close-div-img {
    width: 8%;
    float: right;
  }

  .filter-two .categories-container h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #050f56;
    position: relative;
    margin-top: 10%;
  }
  .filter-two .categories-container h3:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 3px;
    background-color: #fe620d;
  }

  .filter-two .categories-container {
    /* background-color: #fe620d; */
  }

  .four-category-container {
    margin-left: 0% !important;
    /* background-color: #fe620d; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 95% !important;
    gap: 15px !important;
  }
  .filter-two .categories-container .category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column !important;
    margin-top: 20px;
    height: 70px !important;
    width: 100px !important;
    /* margin-left: -15px !important; */
    /* width: 100% !important; */
    /* background-color: #fe620d; */
  }

  .filter-two .categories-container .category .icon-wrapper {
    padding: 10px;
    /* background: #ffffff; */
    /* background-color: #fe620d; */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    transition: none;
    border: 1px solid transparent;
    height: 40px !important;
    width: 40px !important;
  }

  .filter-two .categories-container .category .icon-wrapper img {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
  }

  .filter-two .categories-container .category a {
    width: 60% !important;
    text-align: center;
    /* background-color: #fe620d; */
    /* margin-left: 20px; */
    text-decoration: none;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 8px !important;
    color: #121212;
    margin-top: 3px !important;
  }

  .filter-two .line {
    width: 100%;
    height: 0.1px;
    /* background-color: #d4d4d4; */
    /* background-color: #fe620d; */
  }

  .filter-two .price-container {
    padding: 20px;
    padding-bottom: 0%;
  }

  .filter-two .price-container h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #050f56;
    position: relative;
    margin-bottom: 0%;
  }
  .filter-two .price-container h3:after {
    display: none;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 3px;
    background-color: #fe620d;
  }

  .filter-two .price-container .min-max-controls {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-top: 0px;
  }

  .filter-two .price-container .min-max-controls .price-input {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .filter-two .price-container .min-max-controls .price-input .ranges-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-two .price-container .min-max-controls .price-input span {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 8px;
    color: #757575;
  }

  .filter-two .price-container .min-max-controls .price-input input {
    width: 100%;
    margin-top: 0px;
    padding: 0.5rem 1rem;
    font-family: inherit;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    text-align: center;
    border: none;
  }

  /* .filter-two
    .price-container
    .min-max-controls
    .price-input
    input::placeholder {
    font-size: 16px;
  } */

  .filter-two .price-container .min-max-controls .price-input input:focus {
    outline: none;
  }

  .filter-two .vehicles-stats-container {
    padding: 20px;
  }

  .filter-two .vehicles-stats-container .vehicle-stat {
    width: 100%;
    position: relative;
  }

  .filter-two .vehicles-stats-container .vehicle-stat .stat-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0rem 0rem 0rem;
  }

  .filter-two .vehicles-stats-container .vehicle-stat .list {
    display: flex;
    justify-content: flex-start;
  }

  .filter-two .vehicles-stats-container .vehicle-stat .list.scroll {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    height: 300px;
  }

  .filter-two .vehicles-stats-container .vehicle-stat .list .list-content {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .filter-two .vehicles-stats-container .vehicle-stat .list .list-content p {
    margin: 0;
    text-overflow: ellipsis;
  }

  .filter-two .vehicles-stats-container .vehicle-stat a {
    text-decoration: none;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    color: #121212;
  }

  .filter-two .vehicles-stats-container .vehicle-stat img {
    width: 12px;
    height: 12px;
    object-fit: cover;
  }

  /* vehicles list */
  .vehicles-container .vehicles-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 1rem !important;
    row-gap: 1rem !important;
    width: 100% !important;
    /* background-color: #fe620d !important; */
  }

  .vehicles-container .vehicles-list-container .vehicle-card {
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    width: 100% !important;
  }
  .vehicles-container .vehicles-list-container .vehicle-card a {
    text-decoration: none;
  }

  .vehicles-container .vehicles-list-container .vehicle-card .img-wrapper img {
    width: 100%;
    height: 110px !important;
    object-fit: cover;
  }

  .vehicles-container .vehicles-list-container .vehicle-card .vehicle-info {
    padding: 0.5rem 0.5rem !important;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .name
    h3 {
    color: #050f56;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    margin-bottom: 0% !important;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .name
    .location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .name
    .location
    img {
    height: 10px !important;
    width: 10px !important;
    /* background-color: #fe620d !important; */
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .name
    .location
    span {
    display: inline-block;
    margin-left: 3px !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 10px !important;
    color: #121212;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .truck-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 10px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #e1e1e1;
    /* background-color: #fe620d !important; */
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .truck-stats
    .stat {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px !important;
    width: 30% !important;
    padding: 2px 5px !important;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .truck-stats
    .stat
    span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 6px !important;
    text-align: center;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .selling-price {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px !important;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .selling-price
    p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    text-align: center;
    margin-bottom: 0% !important;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    .selling-price
    p
    span {
    margin-left: 5px !important;
    font-size: 14px !important;
    display: inline-block;
    font-weight: bolder;
    color: #00adef;
  }

  .vehicles-container
    .vehicles-list-container
    .vehicle-card
    .vehicle-info
    button {
    margin-top: 4px !important;
    border: none;
    background: #050f56;
    border-radius: 7px;
    width: 100%;
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    font-size: 12px !important;
    padding: 5px 20px !important;
  }

  .no-data {
    width: 100% !important;
    /* margin: 0 auto; */
    display: flex;
    justify-content: center;
    object-fit: cover;
    /* background-color: #050F56 !important; */
  }

  .state-filter-search-div input::placeholder {
    font-size: 12px;
  }
}

@media screen and (min-width: 1000px) {
  .lg-devices {
    display: none !important;
  }
}

.filter-sort-button-div {
  display: none;
}

.vehicles-container {
  padding: 10px 10px;
  display: flex;
  gap: 20px;
}

.pages-navigation {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.pages-navigation .sort-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 5px 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 8px;
}
.pages-navigation .sort-btn span {
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #050f56;
}
.pages-navigation .sort-btn img {
  width: 12px;
  height: 12px;
  object-fit: cover;
}
.pages-navigation small {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #414141;
}
.pages-navigation .filter-sort {
  position: absolute;
  top: 100%;
  right: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 12532;
}
.pages-navigation .filter-sort .line {
  margin-top: 10px;
  width: 100%;
  height: 0.1px;
  background-color: #d4d4d4;
}
.pages-navigation .filter-sort .filter-sort-container {
  padding: 12px 20px 0;
  width: 200px;
}
.pages-navigation .filter-sort .filter-sort-container.kmsdriven {
  padding: 20px 20px 20px;
}
.pages-navigation .filter-sort .filter-sort-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #050f56;
  position: relative;
}
.pages-navigation .filter-sort .filter-sort-container h3::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: #fe620d;
}
.pages-navigation .filter-sort .filter-sort-container .filter-sort-controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.pages-navigation
  .filter-sort
  .filter-sort-container
  .filter-sort-controls
  span {
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  margin-left: 10px;
}

.vehicles-container .filter-sidebar {
  width: 300px;
}

.vehicles-container .filter-sidebar .filter-one {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.vehicles-container .filter-sidebar .filter-one .line {
  width: 100%;
  height: 0.1px;
  background-color: #d4d4d4;
}

.vehicles-container .filter-sidebar .filter-one .filter-container {
  padding: 20px 20px 0;
}
.vehicles-container .filter-sidebar .filter-one .filter-container.kmsdriven {
  padding: 20px 20px 20px;
}

.vehicles-container .filter-sidebar .filter-one .filter-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #050f56;
  position: relative;
}
.vehicles-container .filter-sidebar .filter-one .filter-container h3:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 3px;
  background-color: #fe620d;
}

.vehicles-container
  .filter-sidebar
  .filter-one
  .filter-container
  .filter-controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.vehicles-container
  .filter-sidebar
  .filter-one
  .filter-container
  .filter-controls
  span {
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  margin-left: 20px;
}

/* Filter Two */
.vehicles-container .filter-sidebar .filter-two {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.vehicles-container .filter-sidebar .filter-two .search-container {
  padding: 20px 20px 0px 0px;
  position: relative;
}
.vehicles-container .filter-sidebar .filter-two .search-container img {
  position: absolute;
  top: 35px;
  left: 10px;
  width: 13px;
  height: 13px;
}
.vehicles-container .filter-sidebar .filter-two .search-container input {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0.5rem 2rem;
  font-family: inherit;
  border: none;
}
.vehicles-container .filter-sidebar .filter-two .search-container input:focus {
  outline: none;
}

.vehicles-container .filter-sidebar .filter-two .categories-container {
  padding: 20px;
}

.vehicles-container .filter-sidebar .filter-two .categories-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #050f56;
  position: relative;
}
.vehicles-container .filter-sidebar .filter-two .categories-container h3:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: #fe620d;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .categories-container
  .category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .categories-container
  .category
  .icon-wrapper {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: none;
  border: 1px solid transparent;
}

.ranges-year {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ranges-year span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.ranges-kms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ranges-kms span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .categories-container
  .category
  .icon-wrapper
  img {
  width: 17px;
  height: 17px;
  object-fit: cover;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .categories-container
  .category
  a {
  text-decoration: none;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .categories-container
  .category
  p {
  margin: 0;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  line-height: 1rem;
  color: #121212;
}

.vehicles-container .filter-sidebar .filter-two .line {
  margin-top: 10px;
  width: 100%;
  height: 0.1px;
  background-color: #d4d4d4;
}

.vehicles-container .filter-sidebar .filter-two .price-container {
  padding: 20px;
}

.vehicles-container .filter-sidebar .filter-two .price-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #050f56;
  position: relative;
}
.vehicles-container .filter-sidebar .filter-two .price-container h3:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: #fe620d;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
  margin-top: 10px;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input
  .ranges-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input
  span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input
  input {
  width: 100%;
  margin-top: 10px;
  padding: 0.5rem 1rem;
  font-family: inherit;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: center;
  border: none;
}
/* .vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input
  input::placeholder {
  font-size: 10px;
} */

.vehicles-container
  .filter-sidebar
  .filter-two
  .price-container
  .min-max-controls
  .price-input
  input:focus {
  outline: none;
}

.vehicles-container .filter-sidebar .filter-two .vehicles-stats-container {
  padding: 5px 20px;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat {
  width: 100%;
  position: relative;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  .stat-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0rem 0rem 0rem;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  .list {
  display: flex;
  justify-content: flex-start;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  .list.scroll {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: scroll;
  height: 300px;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  .list
  .list-content {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  .list
  .list-content
  p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-overflow: ellipsis;
}

.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  a {
  text-decoration: none;
  font-family: "Poppins";
  font-size: 16px;
  color: #121212;
}
.vehicles-container
  .filter-sidebar
  .filter-two
  .vehicles-stats-container
  .vehicle-stat
  img {
  width: 12px;
  height: 12px;
  object-fit: cover;
}

/* vehicles list */

.vehicles-container .vehicles-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  height: fit-content;
  column-gap: 1rem;
  row-gap: 1rem;
  position: relative;
}

.vehicles-container .vehicles-list-container .vehicle-card {
  /* height: fit-content; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.vehicles-container .vehicles-list-container .vehicle-card .uid {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  padding: 0.5rem 1rem;
  background-color: #00adef;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vehicles-container .vehicles-list-container .vehicle-card .uid span {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}

@media screen and (max-width: 768px) {
  .vehicles-container .vehicles-list-container .vehicle-card .uid span {
    font-size: 0.5rem;
  }
}
.vehicles-container .vehicles-list-container .vehicle-card a {
  text-decoration: none;
}

.vehicles-container .vehicles-list-container .vehicle-card .img-wrapper img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.vehicles-container .vehicles-list-container .vehicle-card .vehicle-info {
  padding: 1rem 1rem;
  margin: 0;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .name
  h3 {
  color: #050f56;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .name
  .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .name
  .location
  span {
  display: inline-block;
  margin-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 15px;
  color: #121212;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .truck-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .truck-stats
  .stat {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .truck-stats
  .stat
  span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: capitalize;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .selling-price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .selling-price
  p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  .selling-price
  p
  span {
  margin-left: 10px;
  font-size: 30px;
  display: inline-block;
  font-weight: bolder;
  color: #00adef;
}

.vehicles-container
  .vehicles-list-container
  .vehicle-card
  .vehicle-info
  button {
  margin-top: 5px;
  border: none;
  background: #050f56;
  border-radius: 7px;
  width: 100%;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 20px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* truck section */
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .truck-section {
    padding: 20px !important;
    margin-top: 25px !important;
  }

  .truck-section .image-wrapper {
    border-radius: 5px !important;
    overflow: hidden;
  }
  .truck-section .image-wrapper img {
    width: 100%;
    height: 250px !important;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .truck-section {
    padding: 10px !important;
    margin-top: 15px !important;
  }

  .truck-section .image-wrapper {
    border-radius: 5px !important;
    overflow: hidden;
  }
  .truck-section .image-wrapper img {
    width: 100%;
    height: 10% !important;
    object-fit: cover;
  }
}

.truck-section {
  padding: 0rem 2rem;
  margin-top: 40px;
}

.truck-section .image-wrapper {
  border-radius: 10px;
  overflow: hidden;
}
.truck-section .image-wrapper img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.ril-caption.ril__caption {
  display: flex;
  justify-content: center;
}
.state-filter-search-div {
  margin-bottom: 10px;
  margin-top: 10px;
}
.state-filter-search-div input {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-family: inherit;
  border: none;
  -webkit-appearance: none;
}
.state-filter-search-div input:focus {
  outline: none;
}

.clear-container {
  padding: 10px 10px;
}
.clear-btn {
  margin: 10px 10px 10px 0px;
  width: 100%;
  border: none;
  background-color: #050f56;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 0;
}

.load-more-container {
  grid-row: auto;
  grid-column: 1 / -1;
  justify-self: center;
}
.load-more-container button {
  width: 100%;
  padding: 1rem 4rem;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-radius: 0.5rem;
  background-color: #050f56;
  color: white;
}

.gaddi-loader {
  display: flex;
  justify-content: center;
}
.gaddi-loader img {
  width: 30%;
  height: 30%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .load-more-container button {
    padding: 1rem 1rem;
    font-size: 0.8rem;
  }
}

.apply-filter-btn-web {
  padding-bottom: 0.5rem;
}
.apply-filter-btn-web button {
  width: 100%;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-radius: 0.5rem;
  background-color: #050f56;
  color: white;
}

.filter-btn-resp button {
  margin: 10px 10px 10px 0px;
  width: 100%;
  border: none;
  background-color: #050f56;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 0;
}
