@media screen and (min-width: 768px) and (max-width: 990px) {
  .language-1 {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .language-2 {
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .languages-container {
    display: none !important;
  }

  .location-and-icon {
    display: flex !important;
    /* justify-content: space-around !important; */
    width: 100% !important;
  }

  .location-container {
    display: none !important;
  }

  .sm-device {
    display: none !important;
  }

  .mob-resp-mail-signin {
    width: 70px !important;
    height: 70px !important;
  }

  .my-google-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-gmail-icon.png") !important;
    background-size: cover !important;
  }

  .my-facebook-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-fb-icon.png") !important;
    background-size: cover !important;
  }

  .mob-menue-overlay {
    background-color: transparent !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
  }

  .sell-buy-container-desktop {
    display: none !important;
  }

  .sell-buy-container-moile {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5px 40px;
    background: #050f56;
  }

  .sell-buy-container-moile button {
    padding: 5px 10px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #050f56;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
    border-radius: 2px;
    border: none;
  }

  .sell-buy-container-moile .sell-btn {
    margin-left: 15px;
  }

  .nav-container {
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
    background: #f4f4f4 !important;
    height: auto !important;
    display: flex;
    top: 0 !important;
  }

  .container-fluid {
    justify-content: center !important;
  }

  .navar_div {
    display: flex;
    height: 100%;
    width: 100%;
    /* padding-left: 10px; */
    /* background-color: red; */
  }

  .logo {
    /* background-color: #050f56; */
    width: 23%;
    margin-right: 130px;
  }

  .logo img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin: 0;
    /* padding: 0% !important; */
    /* background-color: olive; */
  }

  .brand-categories {
    display: flex;
    gap: 10px !important;
    height: 50px !important;
    width: 250px !important;
    margin-right: 0px;
    /* margin-left: 10px !important; */
    /* margin-left: -100%; */
    /* background-color: orangered; */
  }

  .brand-categories .brand-category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px !important;
    /* background: #c23131 !important; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    cursor: pointer;
    height: 100% !important;
    width: 90px !important;
  }

  .hamburger-menu {
    width: 50px !important;
    display: block !important;
    cursor: pointer;
    margin-left: 130px !important;
    /* background-color: orchid; */
  }

  .hamburger-menu img {
    width: 40px;
  }

  .brand-categories .brand-category img {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
  }

  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 40%;
    margin-top: 10px !important;
    width: 100% !important;
    margin-left: 0px !important;
    /* background-color: olivedrab; */
    /* width: 200px !important; */
  }

  .search-container input {
    padding: 12px 50px;
    width: 100% !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: none;
  }

  .location-container {
    display: none !important;
  }

  .user {
    display: none !important;
  }

  .mob-languages-container {
    display: block !important;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
    position: relative;
    margin: 10px;
  }

  .mob-languages-container .language {
    width: 100% !important;
  }

  .mob-languages-container .language-arrow-icon {
    position: absolute;
    right: 5%;
    top: 20%;
  }

  .mob-languages-container .language-arrow-icon img {
    width: 20px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1000px) {
  .language-1 {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .language-2 {
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .languages-container {
    display: none !important;
  }

  .location-and-icon {
    display: flex !important;
    /* justify-content: space-around !important; */
    width: 100% !important;
  }

  .location-container {
    display: none !important;
  }

  .sm-device {
    display: none !important;
  }

  .mob-resp-mail-signin {
    width: 70px !important;
    height: 70px !important;
  }

  .my-google-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-gmail-icon.png") !important;
    background-size: cover !important;
  }

  .my-facebook-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-fb-icon.png") !important;
    background-size: cover !important;
  }

  .mob-menue-overlay {
    background-color: transparent !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
  }

  .sell-buy-container-desktop {
    display: none !important;
  }

  .sell-buy-container-moile {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5px 40px;
    background: #050f56;
  }

  .sell-buy-container-moile button {
    padding: 5px 10px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #050f56;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
    border-radius: 2px;
    border: none;
  }

  .sell-buy-container-moile .sell-btn {
    margin-left: 15px;
  }

  .nav-container {
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
    background: #f4f4f4 !important;
    height: auto !important;
    display: flex;
  }

  .container-fluid {
    justify-content: center !important;
  }

  .navar_div {
    display: flex;
    height: 100%;
    width: 100%;
    /* padding-left: 10px; */
    /* background-color: red; */
  }

  .logo {
    /* background-color: #050f56; */
    width: 23%;
    margin-right: 10px;
  }

  .logo img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin: 0;
    /* padding: 0% !important; */
    /* background-color: olive; */
  }

  .brand-categories {
    display: flex;
    gap: 10px !important;
    height: 50px !important;
    width: 250px !important;
    margin-right: 0px;
    /* margin-left: 10px !important; */
    /* margin-left: -100%; */
    /* background-color: orangered; */
  }

  .brand-categories .brand-category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px !important;
    /* background: #c23131 !important; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    cursor: pointer;
    height: 100% !important;
    width: 90px !important;
  }

  .hamburger-menu {
    width: 50px !important;
    display: block !important;
    cursor: pointer;
    margin-left: 130px !important;
    /* background-color: orchid; */
  }

  .hamburger-menu img {
    width: 40px;
  }

  .brand-categories .brand-category img {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
  }

  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 40%;
    margin-top: 10px !important;
    width: 100% !important;
    margin-left: 0px !important;
    /* background-color: olivedrab; */
    /* width: 200px !important; */
  }

  .search-container input {
    padding: 12px 50px;
    width: 100% !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: none;
  }

  .location-container {
    display: none !important;
  }

  .user {
    display: none !important;
  }

  .mob-languages-container {
    display: block !important;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
    position: relative;
    margin: 10px;
  }

  .mob-languages-container .language {
    width: 100% !important;
  }

  .mob-languages-container .language-arrow-icon {
    position: absolute;
    right: 5%;
    top: 20%;
  }

  .mob-languages-container .language-arrow-icon img {
    width: 20px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1236px) {
  .language-1 {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .language-2 {
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .languages-container {
    display: none !important;
  }

  /* .location-and-icon{
    display: flex !important;
    width: 100% !important;
  }
  .location-container {
    display: none !important;
    display: block !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    justify-content: space-between !important;
    
  }
  .sm-device{
    display: none !important;
  } */
  /* .location-container {
    display: none !important;
  } */
  .mob-resp-mail-signin {
    width: 70px !important;
    height: 70px !important;
  }

  .my-google-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-gmail-icon.png") !important;
    background-size: cover !important;
  }

  .my-facebook-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-fb-icon.png") !important;
    background-size: cover !important;
  }

  .logo {
    /* background-color: #050f56; */
    width: 23%;
    margin-right: 10px;
  }
}

/* .header-container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
} */
.sell-buy-container-moile {
  display: none;
}

.sell-buy-container-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  background: #050f56;
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 9999; */
  min-height: 40px;
}

.sell-buy-container-desktop button {
  padding: 5px 20px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #050f56;
  background: #f9f9f9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  border: none;
}

.sell-buy-container-desktop .sell-btn {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .mob-resp-mail-signin {
    width: 70px !important;
    height: 70px !important;
  }

  .my-google-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-gmail-icon.png") !important;
    background-size: cover !important;
  }

  .my-facebook-button-class {
    font-size: 0px !important;
    /* background-color: #050f56; */
    width: 70px !important;
    height: 70px !important;
    background-image: url("../assets/mob-fb-icon.png") !important;
    background-size: cover !important;
  }

  .mob-menue-overlay {
    background-color: transparent !important;
    top: 0px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
  }

  .sell-buy-container-desktop {
    display: none !important;
  }

  .sell-buy-container-moile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 30px 5px 30px;
    background: #050f56;
  }

  .sell-buy-container-moile button {
    padding: 5px 10px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 8px;
    color: #050f56;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
    border-radius: 2px;
    border: none;
  }

  .sell-buy-container-moile .sell-btn {
    margin-left: 15px;
  }

  .nav-container {
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
    background: #f4f4f4 !important;
    /* transform: translateX(10px) !important; */
    height: 112px;
    display: flex;
    /* position: sticky; */
    /* top: 39.5 !important; */
    z-index: 9999;
  }

  .container-fluid {
    justify-content: center !important;
  }

  .navar_div {
    display: flex;
    height: 100%;
    width: 100%;
    /* padding-left: 10px; */
    /* background-color: red; */
  }

  .logo {
    /* background-color: #050f56; */
    width: 26%;
    margin-right: 12px;
  }

  .logo img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin: 0;
    /* background-color: olive; */
  }

  .brand-categories {
    display: flex;
    /* gap: 5px; */
    height: 40px !important;
    width: 49% !important;
    margin-right: 0px;
    /* margin-left: 10px !important; */
    /* margin-left: -100%; */
    /* background-color: orangered; */
  }

  .brand-categories .brand-category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px !important;
    /* background: #c23131 !important; */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    cursor: pointer;
    height: 100% !important;
    width: 90px !important;
  }
}

/* NavBar */
.nav-container {
  background: #f4f4f4;
  /* position: sticky; */
  /* top: 40px; */
  z-index: 9999;
}

.logo img {
  /* width: 100%; */
  height: 60px;
  object-fit: cover;
}

.hamburger-menu {
  display: none;
}

@media screen and (max-width: 767px) {
  .hamburger-menu {
    width: 30px !important;
    display: block;
    cursor: pointer;
    margin-left: 30px;
    /* background-color: orchid; */
  }

  .hamburger-menu img {
    width: 30px;
  }

  .brand-categories .brand-category img {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
  }

  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 40%;
    /* margin-top: 20px; */
    width: 80% !important;
    margin-left: 10px !important;
    /* background-color: olivedrab; */
    /* width: 200px !important; */
  }

  .search-container input {
    padding: 12px 50px;
    width: 98% !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: none;
  }

  .location-and-icon {
    display: flex !important;
    /* justify-content: space-around !important; */
    width: 100% !important;
  }

  .location-container {
    display: none !important;
  }

  .sm-device {
    display: none !important;
  }

  .languages-container {
    display: none !important;
  }
}

.brand-categories {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.brand-categories .brand-category {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  cursor: pointer;
}

.brand-categories .brand-category img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.brand-categories .brand-category.active {
  /* border: 1px solid #00adef; */
  background-color: #00adef;
}

/* search */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 40%;
  margin-left: 12px;
}

.search-container input {
  padding: 12px 40px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  border: none;
}

.search-container input:focus {
  outline: none;
}

.search-container ::placeholder {
  font-size: 12px;
}

.search-container .search-icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(-50%, -50%);
}

.search-nav-suggestion {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 300px;
  overflow: scroll;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.search-nav-suggestion a {
  color: #000000;
  text-transform: capitalize !important;
}

.search-nav-suggestion p {
  cursor: pointer;
  padding: 0px 20px 0;
  text-transform: capitalize !important;
  font-family: "Poppins", sans-serif;
}

.active-item {
  background-color: #dbe9ef;
}

/* .search-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #818181;
} */

/* location */
.location-container {
  display: flex;
  padding: 12px 10px;
  background: #ffffff;
  border-radius: 8px;
  align-items: center;
  margin-left: 10px;
  flex-direction: column;
  gap: 17px;
  position: relative;
}

.location-container .location {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.location-container .location span {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #505050;
  text-transform: capitalize;
  margin-left: 3px;
}

.location-container .location img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.location-container .arrow-icon {
  align-self: baseline;
  cursor: pointer;
  margin-left: 17px;
}

.location-container .arrow-icon img {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.location-container .lang-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 500;
}

.location-container .lang-dropdown .lang-input {
  overflow: scroll;
}

.location-container .lang-dropdown input {
  width: 100%;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-family: "Roboto";
  padding: 5px 10px;
}

.location-container .lang-dropdown input:focus {
  outline: 1px solid #00adef;
}

.location-container .lang-dropdown input::placeholder {
  font-size: 12px;
}

.location-container .lang-dropdown .lang-suggetion {
  padding: 10px 10px;
  /* overflow: scroll; */
}

.location-container .lang-dropdown .lang-suggetion p {
  font-size: 12px;
  margin: 0;
  cursor: pointer;
  text-transform: capitalize;
}

.lang-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .language-1 {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .language-2 {
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }

  .location-container {
    display: none !important;
  }

  .languages-container {
    display: none !important;
  }
}

/* languages */
.languages-container {
  display: flex;
  padding: 12px 10px;
  background: #ffffff;
  border-radius: 8px;
  align-items: center;
  margin-left: 10px;
  gap: 17px;
  position: relative;
  cursor: pointer;
}

.languages-container .language span {
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  color: #505050;
}

.languages-container .language-arrow-icon {
  cursor: pointer;
}

.languages-container .language-arrow-icon img {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .mob-languages-container {
    display: block !important;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
    position: relative;
    margin: 0 10px;
    cursor: pointer;
  }

  .mob-languages-container .language {
    width: 100% !important;
  }

  .mob-languages-container .language-arrow-icon {
    position: absolute;
    right: 5%;
    top: 20%;
  }

  .mob-languages-container .language-arrow-icon img {
    width: 20px;
  }
}

/* user */

.user {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.09);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  color: #505050;
}

.user span {
  margin-left: 10px;
}

.user img {
  width: 20px;
  height: 25px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .user {
    display: none;
  }
}

/* .SignUpModal{
  height: 100vh !important;
  width: 35% !important;
  background-color: #050f56 !important;
} */

.DivSignInWithOptions {
  animation: slide;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes slide {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.slideBack {
  animation: slide-back;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  transition: 0.6s;
}

@keyframes slide-back {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.closing-arrow {
  width: 2rem;
  cursor: pointer;
  margin-bottom: 8%;
}

.parent {
  background-color: rgba(128, 128, 128, 0.274);
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 67%;
  cursor: pointer;
  animation: zoom;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes zoom {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.main_parent {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;

  display: flex;
  justify-content: space-between;
}

.sign-in-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  margin-bottom: 0;
}

.text-color-blue {
  color: #00adef;
}

.sign-in-welcome-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  color: #969696;
}

.google-sign-in-button {
  border: none !important;
  background-color: transparent !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;

  color: #cfcfcf;
}

.my-facebook-button-class {
  border: none;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  color: #cfcfcf;
}

.sign-in-google,
.sign-in-fb,
.sign-in-email {
  border: none;
  height: 60px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-google img,
.sign-in-fb img,
.sign-in-email img {
  width: 1.4rem;
  margin-right: 6px;
  /* margin-right: 2%; */
}

.sign-in-google img {
  /* margin-left: 22%; */
}

.sign-in-fb img {
  /* margin-left: 22%; */
}

.sign-in-email {
  justify-content: center;
}

hr {
  width: 46%;
  display: inline-block;
}

.or-div {
  display: flex;
  align-items: center;
}

.or {
  margin-left: 2%;
  margin-right: 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2vw;
  color: #818181;
}

.phone-no-input {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  height: 50px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 4%;
}

::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 23px;

  color: #9c9c9c;
}

.sign-in-button {
  background: #050f56;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  border: none;
  height: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
}

.create-account {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: end;
  color: #00adef;
  cursor: pointer;
}

a {
  text-decoration: none;
}

/* sign up */
.signup-closing-arrow {
  width: 2rem;
  cursor: pointer;
  margin-bottom: 6%;
}

.signup-parent {
  background-color: rgba(128, 128, 128, 0.274);
  position: fixed;

  z-index: 9999;
  height: 100%;
  width: 67%;
  cursor: pointer;
}

.signup-main_parent {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.signup-sign-in-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  margin-bottom: 6%;
}

.signup-text-color-blue {
  color: #00adef;
}

.signup-sign-in-welcome-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  color: #969696;
}

.signup-sign-in-google,
.signup-sign-in-fb,
.signup-sign-in-email {
  border: none;
  height: 60px;
  margin-top: 20px;
  /* margin-bottom: 2vh; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #cfcfcf;
}

.signup-sign-in-google img,
.signup-sign-in-fb img,
.signup-sign-in-email img {
  width: 1.4rem;
  margin-right: 2%;
}

.signup-sign-in-google img {
  margin-left: 22%;
}

.signup-sign-in-fb img {
  margin-left: 22%;
}

.signup-sign-in-email {
  justify-content: center;
}

.SinguplocationIcon {
  height: 60px;
  margin-bottom: 20px;
  position: relative;
}

.Singup-location-Icon {
  width: 1.2vw;
  position: absolute;
  z-index: 9;
  top: 2px;
  right: 25px;
  transform: translate(50%, 50%);
}

.SingupEyeIconDiv {
  height: 60px;
  margin-bottom: 4%;
  position: relative;
}

.Singup-eye-Icon {
  width: 1.2vw;
  position: absolute;
  z-index: 9;
  top: 2px;
  right: 25px;
  transform: translate(50%, 50%);
}

.signup-location-input {
  width: 100%;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding: 5px 20px;
  height: 100%
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.signup-phone-no-input {
  /* background: #ffffff; */
  /* background-color: #00adef; */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  height: 60px;
  border: none;
  margin-top: 0%;
  margin-bottom: 15px;
  padding-left: 4%;
  /* padding: 0px 20px; */
}

::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 23px;
  font-family: "Poppins";
  color: #999999;
}

.signup-sign-in-button {
  background: #050f56;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  border: none;
  height: 70px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: white;
  /* margin-bottom: 2%; */
  margin-top: 4%;
}

.signup-create-account {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: end;
  color: #00adef;
  cursor: pointer;
}

a {
  text-decoration: none;
}

/* otp */
.otp-main_parent {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.otp-parent {
  background-color: rgba(128, 128, 128, 0.274);
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 67%;
  cursor: pointer;
}

.otp-closing-arrow {
  width: 2rem;
  cursor: pointer;
  margin-bottom: 8%;
}

.otp-closing-arrow.verify {
  margin-bottom: 0%;
}

.otp-sign-in-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  margin-bottom: 0;
}

.otp-welcome-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  color: #969696;
  margin-bottom: 8%;
}

.otp-phone-no-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  color: #969696;
  margin-bottom: 1%;
}

.otp-phone-no-input {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  height: 50px;
  border: none;
  margin-top: 0%;
  margin-bottom: 4%;
  padding-left: 4%;
}

::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 23px;
  font-family: "Poppins";
  color: #999999;
}

.otp-create-account {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  text-align: end;
  /* color: #00ADEF; */
  cursor: pointer;
  margin-top: -2%;
}

.otp-sign-in-button {
  background: #050f56;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  border: none;
  height: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: white;
  /* margin-bottom: 2%; */
  margin-top: 4%;
}

.timer {
  color: #00adef;
  font-weight: bold;
  position: absolute;
  z-index: 9;
  top: 48vh;
  right: 12%;
  transform: translate(50%, 50%);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  vertical-align: none !important;
}

@media screen and (min-width: 1367px) {
  .timer {
    color: #00adef;
    font-weight: bold;
    position: absolute;
    z-index: 9;
    top: 46vh;
    right: 12%;
    transform: translate(50%, 50%);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
  }
}

.otp-text-color-blue {
  color: #00adef;
  font-weight: 600;
  font-family: "Roboto";
  font-style: normal;
}

.location-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

/* dropdown */
.drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9;
  width: 100%;
  left: 0;
  top: 95%;
  text-align: center;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
}

.language-1 {
  border-top: 1px solid #e6e6e6;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #565656;
  padding: 0;
  padding-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;
}

.language-2 {
  border-top: 1px solid #e6e6e6;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #565656;
  padding: 0;
  padding-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;
}

/* .SelectedLanguageDecoration{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4A4A4A;
} */
.password-guide {
  color: #969696;
  align-self: flex-end;
  margin-top: -10px;
  font-size: xx-small;
}

.signup-other-option-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #cfcfcf;
  /* background-color: #050f56; */
  /* padding-left: -50px !important; */
}

.website-google-signup-img {
  width: 35px;
}

.website-fb-signup-img {
  width: 60%;
}

.website-facebook-sign-up-button-class {
  align-self: center;
  justify-self: center;
  background-image: url("../assets/mob-fb-icon.png");
  font-size: 0px !important;
  border: none;
  background-size: cover;
  /* background-color: #050f56; */
  background-color: transparent;
  width: 35px !important;
  height: 35px !important;
  /* margin-top: 5%; */
}

.box-1,
.box-2 {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-1 {
  width: 30%;
}

.box-2 {
  width: 30%;
  /* justify-content: center; */
  /* padding: 0 30px; */
  /* padding-left: 22px; */
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.paddingTop-5 {
  /* background-color: #00adef; */
  margin-top: 0px;
  margin-bottom: 0% !important;
  /* transform: translateY(20px) !important; */
  display: inline !important;
  /* margin-top: 20px !important; */
}

.sign-up-loaction-drop-down {
  overflow-y: scroll;
  position: absolute;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
  /* background-color: orange; */
  top: 95%;
  width: 100%;
  height: 200px;
  z-index: 99999;
}

/* .mob-menue-overlay {
  background-color:transparent !important;
  width: 100vw !important;
  height: 100vh !important;
  position: absolute !important;
  top: 0% !important;
  z-index: 99;
  left: 0%;
  overflow-x: hidden;
} */

@media screen and (max-width: 1000px) {
  .website-google-signup-img {
    width: 35px !important;
  }

  .box-1 button {
    font-size: 10px !important;
  }

  .mob-signin-resp .or {
    font-size: 10px !important;
  }

  .paddingTop-5 {
    font-size: 10px !important;
  }
}

.header {
  position: sticky !important;
  top: 0% !important;
  z-index: 999 !important;
}

.Logged-user-name {
  padding: 0 10px 0;
  padding-bottom: 0%;
  color: #050f56;
  font-weight: bold;
}

.otp-timer {
  /* background-color: #050f56; */
  align-self: flex-end !important;
  color: #00adef;
}

.otp-timer p {
  display: inline;
}

@media screen and (max-width: 1000px) {
  .otp-timer {
    /* background-color: #050f56; */
    align-self: flex-end !important;
    color: #00adef;
    justify-content: flex-end;
    float: right;
  }
}