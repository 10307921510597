@media screen and (min-width: 0px) and (max-width: 767px) {
  .mob-intro-right {
    display: none !important;
  }
  .carousel-container {
    position: relative;
    height: 200px !important;
    /* background-color: #fe620d; */
    margin-bottom: 30px !important;
    /* padding-bottom: 200px !important; */
  }
  .carousel-container img {
    width: 100%;
    height: 200px !important;
    /* height: ; */
    object-fit: cover;
  }
  .carousel-container span {
    width: 25px !important;
    height: 5px;
    border-radius: 5px;
  }
  .carousel-container .intro-left {
    position: absolute;
    top: 25%;
    left: 20px;
    z-index: 100;
    width: 200px;
  }
  .carousel-container .intro-left h1 {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 12px !important;
    color: #ffffff;
  }
  .carousel-container .intro-left p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 10px !important;
    color: #b5b5b5;
    display: inline-block;
    word-wrap: break-word;
    width: 200px !important;
    /* text-overflow: ellipsis; */
  }

  /* Categories Modal */
  .intro-right {
    position: static !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    width: 80% !important;
    height: auto;
    background-color: #ffffff;
    /* background-color: #fe620d !important; */
    padding: 20px !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translate(0%, 0%) !important;
    z-index: 50;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left: 10% !important;
    /* padding-bottom: 0% !important; */
  }

  .intro-right h2 {
    font-family: "poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #050f56;
    position: relative;
  }
  .intro-right h2:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 80px !important;
    height: 3px;
    background-color: #fe620d;
    border-radius: 5px;
  }

  .intro-right .categories {
    padding: 30px;
  }

  .intro-right .categories .row-one {
    display: flex;
    gap: 50px !important;
    justify-content: center !important;
    /* background-color: #fe620d !important; */
  }

  .intro-right .categories .row-one .category-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .intro-right .categories .row-one .category-item .category {
    background: #ffffff;
    /* background-color: #fe620d !important; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;
    border: 2px solid transparent;
  }
  .intro-right .categories .row-one .category-item .category.active {
    border: 2px solid #00adef;
  }

  .intro-right .categories .row-one .category-item .category img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
  }

  .intro-right .categories .row-one .category-item span {
    display: inline-block;
    font-family: "Poppins";
    text-align: center;
    font-style: normal;
    font-size: 12px !important;
    color: #121212;
    margin-top: 5px;
  }

  .intro-right .categories .row-two {
    margin-top: 25px;
    /* margin-left: 10% !important; */
    display: flex;
    align-items: flex-start !important;
    justify-content: center !important;
    gap: 47px !important;
    /* background-color: #fe620d !important; */
  }

  .intro-right .categories .row-two .category-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30% !important;
  }

  .intro-right .categories .row-two .category-item .category {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: none;
    border: 2px solid transparent;
  }
  .intro-right .categories .row-two .category-item .category.active {
    border: 2px solid #00adef;
  }

  .intro-right .categories .row-two .category-item .category img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
  }

  .intro-right .categories .row-two .category-item span {
    display: block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 12px !important;
    color: #121212;
    text-align: center;
    margin-top: 5px;
    line-height: 20px;
  }

  .how-it-works-section {
    margin-bottom: 30px !important;
  }
}
/* for banner height */
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .mob-intro-right {
    display: none !important;
  }
  .carousel-container {
    position: relative;
    height: 300px !important;
    /* background-color: #fe620d; */
    margin-bottom: 30px !important;
    /* padding-bottom: 200px !important; */
  }
  .carousel-container img {
    width: 100%;
    height: 300px !important;
    /* height: ; */
    object-fit: cover;
  }
  .carousel-container span {
    width: 25px !important;
    height: 5px;
    border-radius: 5px;
  }
  .carousel-container .intro-left {
    position: absolute;
    top: 25%;
    left: 20px;
    z-index: 100;
    width: 200px;
  }
  .carousel-container .intro-left h1 {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 12px !important;
    color: #ffffff;
  }
  .carousel-container .intro-left p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 10px !important;
    color: #b5b5b5;
    display: inline-block;
    word-wrap: break-word;
    width: 200px !important;
    /* text-overflow: ellipsis; */
  }

  /* Categories Modal */
  .intro-right {
    position: static !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    width: 80% !important;
    height: auto;
    background-color: #ffffff;
    /* background-color: #fe620d !important; */
    padding: 20px !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translate(0%, 0%) !important;
    z-index: 50;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left: 10% !important;
    /* padding-bottom: 0% !important; */
  }

  .intro-right h2 {
    font-family: "poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #050f56;
    position: relative;
  }
  .intro-right h2:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 80px !important;
    height: 3px;
    background-color: #fe620d;
    border-radius: 5px;
  }

  .intro-right .categories {
    padding: 30px;
  }

  .intro-right .categories .row-one {
    display: flex;
    gap: 50px !important;
    justify-content: center !important;
    /* background-color: #fe620d !important; */
  }

  .intro-right .categories .row-one .category-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .intro-right .categories .row-one .category-item .category {
    background: #ffffff;
    /* background-color: #fe620d !important; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;
    border: 2px solid transparent;
  }
  .intro-right .categories .row-one .category-item .category.active {
    /* border: 2px solid #00adef; */
    background-color: #00adef;
  }

  .intro-right .categories .row-one .category-item .category img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
  }

  .intro-right .categories .row-one .category-item span {
    display: inline-block;
    font-family: "Poppins";
    text-align: center;
    font-style: normal;
    font-size: 12px !important;
    color: #121212;
    margin-top: 5px;
  }

  .intro-right .categories .row-two {
    margin-top: 25px;
    /* margin-left: 10% !important; */
    display: flex;
    align-items: flex-start !important;
    justify-content: center !important;
    gap: 47px !important;
    /* background-color: #fe620d !important; */
  }

  .intro-right .categories .row-two .category-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30% !important;
  }

  .intro-right .categories .row-two .category-item .category {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: none;
    border: 2px solid transparent;
  }
  .intro-right .categories .row-two .category-item .category.active {
    /* border: 2px solid #00adef; */
    background-color: #00adef;
  }

  .intro-right .categories .row-two .category-item .category img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
  }

  .intro-right .categories .row-two .category-item span {
    display: block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 12px !important;
    color: #121212;
    text-align: center;
    margin-top: 5px;
    line-height: 20px;
  }

  .how-it-works-section {
    margin-bottom: 30px !important;
  }
}
/* @media screen and (max-width: 767px) {
  .carousel-container {
    position: relative;
    height: 200px !important;
    
  }
  .carousel-container img {
    width: 100%;
    height: 200px !important;
    object-fit: cover;
  }
  .carousel-container span {
    width: 25px !important;
    height: 5px;
    border-radius: 5px;
  }
  .carousel-container .intro-left {
    position: absolute;
    top: 25%;
    left: 20px;
    z-index: 100;
    width: 200px;
  }
  .carousel-container .intro-left h1 {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 12px !important;
    color: #ffffff;
  }
  .carousel-container .intro-left p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 10px !important;
    color: #b5b5b5;
    display: inline-block;
    word-wrap: break-word;
    width: 200px !important;
  }
 

.intro-right-container{
  background-color: rebeccapurple;
  position: static !important;
  width: 100% !important;
  
}

.intro-right {
  position :static !important;
  width: 100% !important;
  height: auto !important;
  background-color: #ffffff;
  padding: 60px 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  z-index: 50;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 6px;
}

.intro-right h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px !important;
  color: #050f56;
  position: relative;
}
.intro-right h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 117.75px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
}

.intro-right .categories {
  padding: 30px;
}

.intro-right .categories .row-one {
  display: flex;
  gap: 40px;
}

.intro-right .categories .row-one .category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.intro-right .categories .row-one .category-item .category {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
  border: 2px solid transparent;
}
.intro-right .categories .row-one .category-item .category.active {
  border: 2px solid #00adef;
}

.intro-right .categories .row-one .category-item .category img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.intro-right .categories .row-one .category-item span {
  display: inline-block;
  font-family: "Poppins";
  text-align: center;
  font-style: normal;
  font-size: 16px;
  color: #121212;
  margin-top: 5px;
}

.intro-right .categories .row-two {
  margin-top: 25px;
  display: flex;
  gap: 40px;
}

.intro-right .categories .row-two .category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.intro-right .categories .row-two .category-item .category {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: none;
  border: 2px solid transparent;
}
.intro-right .categories .row-two .category-item .category.active {
  border: 2px solid #00adef;
}

.intro-right .categories .row-two .category-item .category img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.intro-right .categories .row-two .category-item span {
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  color: #121212;
  text-align: center;
  margin-top: 5px;
  line-height: 20px;
}
} */

.carousel-container {
  position: relative;
}
.carousel-container img {
  width: 100%;
  height: 85vh;
  object-fit: cover;
}
.carousel-container span {
  width: 70px;
  height: 5px;
  border-radius: 5px;
}

.carousel-container .intro-left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 25%;
  left: 20px;
  z-index: 100;
}
.carousel-container .intro-left h1 {
  font-family: "Poppins";
  font-weight: bolder;
  font-size: 45px;
  color: #ffffff;
}
.carousel-container .intro-left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  display: inline-block;
  word-wrap: break-word;
  width: 600px;
}

/* Categories Modal */
.intro-right {
  position: absolute;
  top: 50%;
  left: 78%;
  right: 0%;
  width: 35%;
  height: auto;
  background-color: #ffffff;
  padding: 60px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  z-index: 50;
}

.intro-right h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #050f56;
  position: relative;
}
.intro-right h2:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 117.75px;
  height: 2px;
  background-color: #fe620d;
  border-radius: 5px;
}

.intro-right .categories {
  padding: 30px;
}

.intro-right .categories .row-one {
  display: flex;
  gap: 40px;
}

.intro-right .categories .row-one .category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.intro-right .categories .row-one .category-item .category {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
  border: 2px solid transparent;
}
.intro-right .categories .row-one .category-item .category.active {
  /* border: 2px solid #00adef; */
  background-color: #00adef;
}

.intro-right .categories .row-one .category-item .category img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.intro-right .categories .row-one .category-item span {
  display: inline-block;
  font-family: "Poppins";
  text-align: center;
  font-style: normal;
  font-size: 16px;
  color: #121212;
  margin-top: 5px;
}

.intro-right .categories .row-two {
  margin-top: 25px;
  display: flex;
  gap: 40px;
}

.intro-right .categories .row-two .category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.intro-right .categories .row-two .category-item .category {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: none;
  border: 2px solid transparent;
}
.intro-right .categories .row-two .category-item .category.active {
  /* border: 2px solid #00adef; */
  background-color: #00adef;
}

.intro-right .categories .row-two .category-item .category img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.intro-right .categories .row-two .category-item span {
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  color: #121212;
  text-align: center;
  margin-top: 5px;
  line-height: 20px;
}
