@media screen and (min-width: 768px) and (max-width: 1000px) {
  .footer-section {
    display: none !important;
  }
  .mobile-footer-section {
    display: visible !important;
    margin-top: 10px !important;
    width: 100% !important;
    height: auto !important;
    background: url("../assets/mobile-footer.svg") no-repeat top/cover !important;
    position: relative;
    background-size: cover !important;
    /* background-color: red !important; */
  }

  /* Footer social */
  .mobile-social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 60px !important;
    right: 15px !important;
  }
  .mobile-social-title h5 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px !important;
    text-transform: uppercase;
    text-decoration: underline;
    color: #050f56;
    /* background-color: #fe620d; */
    margin-right: 5px !important;
    text-align: center !important;
  }
  .mobile-social-links img {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
    margin: 0px 8px 0px 0px !important;
  }

  /* Footer Container */
  .mobile-footer-container {
    padding: 20px !important;
    padding-bottom: 40px !important;
  }

  .mobile-footer-container .mobile-break-line {
    display: flex;
    align-items: center;
    margin-top: 0px;
    padding: 0px 0px;
    width: 100%;
    /* background-color: #fe620d; */
  }
  .mobile-footer-container .mobile-break-line span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .mobile-footer-container .mobile-break-line .mobile-line {
    width: 100%;
    height: 0.5px;
    background-color: #ffffff;
    margin: 0;
  }

  .mobile-footer-top {
    margin-top: 100px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* background-color: rebeccapurple; */
  }
  .mobile-footer-logo {
    /* background-color: #fe620d; */
    width: 200px !important;
  }
  .mobile-footer-logo img {
    width: 100% !important;
  }
  .mobile-footer-top .mobile-logo-content p {
    width: 250px !important;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px !important;
    color: #ffffff;
    /* background-color: #fe620d; */
    margin: 0;
    margin-top: 25px;
    margin-bottom: 15px;
    /* word-break: break-all; */
  }

  /* Footer Sitemap one */
  .mobile-footer-top .mobile-footer-sitemap-one {
    margin-top: 15px !important;
    /* background-color: #fe620d; */

    /* width: 100px; */
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul {
    padding: 0%;
    list-style-type: none;
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul li {
    margin: 0px;
    margin-bottom: 10px;
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul li > a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #ffffff;
    /* background-color: #fe620d; */
    margin-left: 0%;
    display: block;
    width: 100% !important;
  }

  /* Footer Sitemap two */
  .mobile-footer-top .mobile-footer-sitemap-two {
    margin-top: 15px !important;
    /* background-color: #fe620d; */
    transform: translateX(-560%);
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul {
    padding: 0%;

    list-style-type: none;
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul li {
    margin: 0px;
    margin-bottom: 10px;
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul li > a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #ffffff;
    display: block;
    width: 100% !important;
  }

  /* footer-download */
  .mobile-footer-top .mobile-footer-download {
    margin-top: 100px !important;
    margin-bottom: 15px;
    /* background-color: #fe620d; */
  }
  .mobile-footer-top .mobile-footer-download .mobile-app-icons {
    display: flex;
    gap: 1rem;
  }
  .mobile-footer-top .mobile-footer-download .mobile-footer-download-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mobile-footer-top
    .mobile-footer-download
    .mobile-footer-download-title
    span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 12px !important;
    color: #ffffff;
  }
  .mobile-footer-top .mobile-footer-download .mobile-footer-download-title h5 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 15px !important;
    text-decoration-line: underline;
    color: #ffffff;
  }
  .mobile-footer-top .mobile-footer-download .mobile-app-icons img {
    width: auto !important;
    height: 60px !important;
    object-fit: cover;
  }

  /* Footer Bottom */
  .mobile-footer-bottom {
    display: flex;
    margin-top: 15px !important;
  }

  .mobile-footer-bottom .mobile-top-cities h3 {
    text-transform: uppercase;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px !important;
    color: #ffffff;
    position: relative;
  }
  .mobile-footer-bottom .mobile-top-cities h3::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    width: 45px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
  }
  .mobile-footer-bottom .mobile-top-cities .mobile-cities {
    display: flex;
  }
  .mobile-footer-bottom .mobile-top-cities .mobile-cities .mobile-cities-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 20px 0px 0px !important;
    position: relative;
  }

  .mobile-footer-bottom .mobile-top-cities .mobile-cities .mobile-cities-row a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 8px !important;
    color: #ffffff;
    position: relative;
    margin: 10px 0px 0px 0px !important;
  }

  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border {
    position: absolute;
    top: 17px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 2px !important;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 26px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 48px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 70px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }

  /* top brands */
  .mobile-top-brands {
    transform: translateX(50px);
  }
  .mobile-footer-bottom .mobile-top-brands h3 {
    text-transform: uppercase;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px !important;
    color: #ffffff;
    position: relative;
  }
  .mobile-footer-bottom .mobile-top-brands h3::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    width: 43px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
  }
  .mobile-footer-bottom .mobile-top-brands .mobile-brands {
    display: flex;
  }
  .mobile-footer-bottom .mobile-top-brands .mobile-brands .mobile-brands-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 20px 0px 0px !important;
    position: relative;
  }

  .mobile-footer-bottom .mobile-top-brands .mobile-brands .mobile-brands-row a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 8px !important;
    color: #ffffff;
    position: relative;
    margin: 10px 0px 0px 0px !important;
  }

  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border {
    position: absolute;
    top: 17px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 26px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 48px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 70px !important;
    left: 125%;
    width: 1px !important;
    height: 12px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }

  /* Footer Copyright */
  .mobile-footer-copyright {
    display: flex;
    justify-content: flex-end;
    /* background-color: #fe620d; */
    padding-right: 10px !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
  }

  .mobile-footer-copyright p {
    color: #ffffff;
    font-size: 5px !important;
  }
  .mobile-footer-copyright p > a {
    color: #ffffff;
    font-size: 5px !important;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .footer-section {
    display: none !important;
  }
  .mobile-footer-section {
    margin-top: 10px !important;
    width: 100% !important;
    height: auto !important;
    background: url("../assets/mobile-footer.svg") no-repeat top/cover !important;
    position: relative;
    background-size: cover !important;
    /* background-color: red !important; */
  }

  /* Footer social */
  .mobile-social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 15px !important;
    right: 10px !important;
  }
  .mobile-social-title h5 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 12px !important;
    text-transform: uppercase;
    text-decoration: underline;
    color: #050f56;
    /* background-color: #fe620d; */
    margin-right: 5px !important;
    text-align: center !important;
  }
  .mobile-social-links img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
    margin: 0px 8px 0px 0px !important;
  }

  /* Footer Container */
  .mobile-footer-container {
    padding: 10px !important;
    padding-bottom: 0px !important;
  }

  .mobile-footer-container .mobile-break-line {
    display: flex;
    align-items: center;
    margin-top: 0px;
    padding: 0px 0px;
    width: 100%;
    /* background-color: #fe620d; */
  }
  .mobile-footer-container .mobile-break-line span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .mobile-footer-container .mobile-break-line .mobile-line {
    width: 100%;
    height: 0.5px;
    background-color: #ffffff;
    margin: 0;
  }

  .mobile-footer-top {
    margin-top: 100px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* background-color: rebeccapurple; */
  }
  .mobile-footer-logo {
    /* background-color: #fe620d; */
    width: 120px !important;
    margin-top: -20px !important;
  }
  .mobile-footer-logo img {
    width: 100% !important;
  }
  .mobile-footer-top .mobile-logo-content p {
    width: 170px !important;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 8px !important;
    color: #ffffff;
    /* background-color: #fe620d; */
    margin: 0;
    margin-top: 25px;
    margin-bottom: 15px;
    /* word-break: break-all; */
  }

  /* Footer Sitemap one */
  .mobile-footer-top .mobile-footer-sitemap-one {
    margin-top: 15px !important;
    /* background-color: #fe620d; */

    /* width: 100px; */
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul {
    padding: 0%;
    list-style-type: none;
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul li {
    margin: 0px;
    margin-bottom: 10px;
  }
  .mobile-footer-top .mobile-footer-sitemap-one ul li > a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #ffffff;
    /* background-color: #fe620d; */
    margin-left: 0%;
    display: block;
    width: 100% !important;
  }

  /* Footer Sitemap two */
  .mobile-footer-top .mobile-footer-sitemap-two {
    margin-top: 15px !important;
    /* background-color: #fe620d; */
    transform: translateX(-170%);
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul {
    padding: 0%;

    list-style-type: none;
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul li {
    margin: 0px;
    margin-bottom: 10px;
  }
  .mobile-footer-top .mobile-footer-sitemap-two ul li > a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 10px !important;
    color: #ffffff;
    display: block;
    width: 100% !important;
  }

  /* footer-download */
  .mobile-footer-top .mobile-footer-download {
    margin-top: 40px !important;
    margin-bottom: 15px;
    /* background-color: #fe620d; */
  }
  .mobile-footer-top .mobile-footer-download .mobile-app-icons {
    display: flex;
    gap: 1rem;
  }
  .mobile-footer-top .mobile-footer-download .mobile-footer-download-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mobile-footer-top
    .mobile-footer-download
    .mobile-footer-download-title
    span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 10px !important;
    color: #ffffff;
  }
  .mobile-footer-top .mobile-footer-download .mobile-footer-download-title h5 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px !important;
    text-decoration-line: underline;
    color: #ffffff;
  }
  .mobile-footer-top .mobile-footer-download .mobile-app-icons img {
    width: auto !important;
    height: 40px !important;
    object-fit: cover;
  }

  /* Footer Bottom */
  .mobile-footer-bottom {
    display: flex;
    margin-top: 15px !important;
  }

  .mobile-footer-bottom .mobile-top-cities h3 {
    text-transform: uppercase;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 12px !important;
    color: #ffffff;
    position: relative;
  }
  .mobile-footer-bottom .mobile-top-cities h3::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    width: 35px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
  }
  .mobile-footer-bottom .mobile-top-cities .mobile-cities {
    display: flex;
  }
  .mobile-footer-bottom .mobile-top-cities .mobile-cities .mobile-cities-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 20px 0px 0px !important;
    position: relative;
  }

  .mobile-footer-bottom .mobile-top-cities .mobile-cities .mobile-cities-row a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 5px !important;
    color: #ffffff;
    position: relative;
    margin: 10px 0px 0px 0px !important;
  }

  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border {
    position: absolute;
    top: 14px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 2px !important;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 22px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 40px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-cities
    .mobile-cities
    .mobile-cities-row
    .mobile-border:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 58px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }

  /* top brands */
  .mobile-footer-bottom .mobile-top-brands h3 {
    text-transform: uppercase;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 12px !important;
    color: #ffffff;
    position: relative;
  }
  .mobile-footer-bottom .mobile-top-brands h3::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    width: 33px !important;
    height: 2px !important;
    background-color: #fe620d;
    border-radius: 5px;
  }
  .mobile-footer-bottom .mobile-top-brands .mobile-brands {
    display: flex;
  }
  .mobile-footer-bottom .mobile-top-brands .mobile-brands .mobile-brands-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 20px 0px 0px !important;
    position: relative;
  }

  .mobile-footer-bottom .mobile-top-brands .mobile-brands .mobile-brands-row a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 5px !important;
    color: #ffffff;
    position: relative;
    margin: 10px 0px 0px 0px !important;
  }

  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border {
    position: absolute;
    top: 14px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(2)::after {
    content: "";
    position: absolute;
    top: 22px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 40px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .mobile-footer-bottom
    .mobile-top-brands
    .mobile-brands
    .mobile-brands-row
    .mobile-border:nth-of-type(4)::after {
    content: "";
    position: absolute;
    top: 58px !important;
    left: 125%;
    width: 1px !important;
    height: 10px !important;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }

  /* Footer Copyright */
  .mobile-footer-copyright {
    display: flex;
    justify-content: center !important;
    margin-top: 10px;
    /* background-color: #fe620d; */
    /* padding-right: 10px !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important; */
  }

  .mobile-footer-copyright p {
    color: #ffffff;
    font-size: 10px !important;
  }
  .mobile-footer-copyright p > a {
    color: #ffffff;
    font-size: 10px !important;
    text-decoration: none;
  }
}

@media screen and (min-width: 1000px) {
  .mobile-footer-section {
    display: none;
  }
}

.footer-section {
  /* margin-top: 10px; */
  background: url("../assets/footer-bg.svg") no-repeat top/cover;
  position: relative;
}

/* Footer social */
.social-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  float: right;
  margin-top: 100px;
  margin-right: 10px;
  /* position: a;
  top: 100px;
  right: 20px; */
}
.social-title h5 {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #050f56;
}
.social-links img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px 0px 0px;
}

/* Footer Container */
.footer-container {
  padding: 50px 50px 15px;
}

.footer-container .break-line {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 0px 0px;
}
.footer-container .break-line span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 50%;
}
.footer-container .break-line .line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin: 0;
}

.footer-top {
  margin-top: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer-top .logo-content {
  margin-right: 50px;
}
.footer-top .logo-content p {
  width: 450px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 25px 0 0;
}

.footer-top .logo-content .footer-logo img {
  width: 400px;
  height: 100%;
  object-fit: cover;
}

/* Footer Sitemap one */
.footer-top .footer-sitemap-one {
  margin-top: 100px;
  margin-right: 50px;
}
.footer-top .footer-sitemap-one ul {
  list-style-type: none;
}
.footer-top .footer-sitemap-one ul li {
  margin: 20px 0px 0px 0px;
}
.footer-top .footer-sitemap-one ul li > a {
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

/* Footer Sitemap two */
.footer-top .footer-sitemap-two {
  margin-top: 100px;
  margin-right: 50px;
}
.footer-top .footer-sitemap-two ul {
  list-style-type: none;
}
.footer-top .footer-sitemap-two ul li {
  margin: 20px 0px 0px 0px;
}
.footer-top .footer-sitemap-two ul li > a {
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

/* footer-download */
.footer-top .footer-download {
  margin-top: 120px;
}
.footer-top .footer-download .app-icons {
  display: flex;
  gap: 1rem;
}
.footer-top .footer-download .footer-download-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-top .footer-download .footer-download-title span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}
.footer-top .footer-download .footer-download-title h5 {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 20px;
  text-decoration-line: underline;
  color: #ffffff;
}
.footer-top .footer-download .app-icons img {
  width: auto;
  height: 60px;
  object-fit: cover;
}

/* Footer Bottom */
.footer-bottom {
  display: flex;
  margin-top: 0;
}

.footer-bottom .top-cities h3 {
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  position: relative;
}
.footer-bottom .top-cities h3::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;
  width: 63px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
}
.footer-bottom .top-cities .cities {
  display: flex;
}
.footer-bottom .top-cities .cities .cities-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0px 50px 0px 0px;
  position: relative;
}

.footer-bottom .top-cities .cities .cities-row a {
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  position: relative;
  margin: 7px 0 0;
}

.footer-bottom .top-cities .cities .cities-row .border {
  position: absolute;
  top: 19px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-cities .cities .cities-row .border:nth-of-type(2)::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-cities .cities .cities-row .border:nth-of-type(3)::after {
  content: "";
  position: absolute;
  top: 70px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-cities .cities .cities-row .border:nth-of-type(4)::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

/* top brands */
.footer-bottom .top-brands h3 {
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  position: relative;
}
.footer-bottom .top-brands h3::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;
  width: 63px;
  height: 3px;
  background-color: #fe620d;
  border-radius: 5px;
}
.footer-bottom .top-brands .brands {
  display: flex;
}
.footer-bottom .top-brands .brands .brands-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0px 50px 0px 0px;
  position: relative;
}

.footer-bottom .top-brands .brands .brands-row a {
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  position: relative;
  margin: 7px 0 0;
}

.footer-bottom .top-brands .brands .brands-row .border {
  position: absolute;
  top: 19px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-brands .brands .brands-row .border:nth-of-type(2)::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-brands .brands .brands-row .border:nth-of-type(3)::after {
  content: "";
  position: absolute;
  top: 70px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.footer-bottom .top-brands .brands .brands-row .border:nth-of-type(4)::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 125%;
  width: 2px;
  height: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

/* Footer Copyright */
.footer-copyright {
  display: flex;
  justify-content: center;
  padding-right: 20px;
}

.footer-copyright p {
  color: #ffffff;
  font-size: 14px;
}
.footer-copyright p > a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
}

@media screen and (min-width: 1368px) {
  .social-container {
    top: 20px;
  }
}
