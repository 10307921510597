.years-container {
  margin-top: 20px;
}

.years-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.year-fields-contianer {
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  padding: 20px 20px;
}

.years-list {
  overflow-y: scroll;
  height: 45vh;
}

.year-data {
  cursor: pointer;
}
.year-data p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #696969;
  margin: 10px 0px;
}
.year-data p:hover {
  color: #050f56;
}

.year-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
.year-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.year-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

@media screen and (max-width: 1000px) {
  .year-btns button {
    width: 100%;
  }
}
