@media screen and (max-width: 1000px) {
  .outside-container {
    /* background-color: #fe620d; */
    padding: 0% !important;
    margin: 0% !important;
  }
  .profile-container {
    /* background-color: #00adef !important; */
    width: 95% !important;
    padding-top: 20px !important;
    /* padding: 0% !important; */
    margin: 0% !important;
  }

  .vehicle-card-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px !important;
  }
  .card {
    width: 100%;
    position: relative;
  }

  .vehicle-card-container .card {
    /* background-color: #00b127  !important; */

    display: flex;
    flex-direction: column !important;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem !important;
    align-items: center;
    height: auto !important;
    /* background-color: #fe620d !important; */
  }
  .card .card-img-wrapper {
    width: 100px !important;
    /* height: 90px !important; */
    height: 100% !important;
  }
  .card .card-img-wrapper.buying-enq {
    width: 100px !important;
    /* height: 90px !important; */
    height: 100% !important;
  }

  .card .card-img-wrapper img {
    /* background-color: aqua; */
    width: 100%;
    height: 100% !important;
    object-fit: contain;
    /* align-self: center; */
  }

  .card .card-info {
    padding: 20px 20px;
    padding-right: 0% !important;
    margin-bottom: 0px !important;
    width: 95% !important;
    position: relative !important;
    display: flex;
    flex-direction: column;
    /* background-color: #fe620d !important; */
  }
  .card .card-info .card-info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;
  }
  .card .card-info .card-info-header .card-info-title {
    display: flex;
    flex-direction: column;
  }
  .card .card-info .card-info-header .card-info-title .location {
    display: flex;
    align-items: center;
  }
  .card .card-info .card-info-header .card-info-title h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 13px !important;
    color: #050f56;
    margin: 0;
    padding: 0;
  }
  .card .card-info .card-info-header .card-info-title .location img {
    /* background-color: #fe620d !important; */
    height: 10px !important;
    width: 10px !important;
  }
  .card .card-info .card-info-header .card-info-title .location span {
    display: inline-block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px !important;
    margin-left: 2px !important;
    color: #121212;
  }

  .card .card-info .card-info-header .card-publish-review {
    display: flex;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    flex-direction: column;
  }
  .card .card-info .card-info-header .card-publish-review .review {
    background: rgba(254, 98, 13, 0.15);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    padding: 3px 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    /* background-color: #00b127 !important; */
  }
  .card .card-info .card-info-header .card-publish-review .review strong {
    display: inline-block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px !important;
    color: #fe620d;
    margin: 0;
  }
  .card .card-info .card-info-header .card-publish-review span {
    display: inline-block;
    margin-top: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 5px !important;
    color: #000000;
  }

  .card .card-info .card-price {
    margin-top: 5px !important;
    margin-bottom: 0% !important;
    position: absolute !important;
    top: 30%;
    /* background-color: #00b127 !important; */
  }
  .card .card-info .card-price h3 {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    color: #00adef;
  }

  .card .card-info .card-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px !important;
    padding: 0% !important;
    width: 100% !important;
    /* background-color: #00adef !important; */
  }
  .card .card-info .card-stats .stat {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px !important;
    padding: 5px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem;
    /* margin-right: 0% !important; */
    /* background-color: #fe620d !important; */
    width: 30% !important;
  }
  .card .card-info .card-stats .stat:nth-child(3) {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px !important;
    padding: 5px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem;
    margin-right: 0% !important;
    /* background-color: #fe620d !important; */
    width: 30% !important;
  }
  .card .card-info .card-stats .stat span {
    display: inline-block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 6px !important;
    color: #121212;
  }

  .vehicle-card-container .card .card-btns {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 5px !important;
  }

  .vehicle-card-container .card button {
    border: none;
    width: 100%;
    padding: 4px 0px !important;
    font-family: "Roboto";
    font-size: 8px !important;
    background: #050f56;
    border-radius: 5px;
    color: #ffffff;
  }

  .vehicle-card-container .card button:nth-child(2) {
    width: 24% !important;
    background: #ffffff;
    color: #050f56;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }
  .vehicle-card-container .card button:nth-child(3) {
    width: 24% !important;
    border: 1px solid #ff0000;
    background: #ffffff;
    color: #ff0000;
  }

  .vehicle-card-container .card .del-btn {
    background-color: rgb(233, 131, 131);
  }
  .card .card-info .card-info-header .review-container .sold-btn {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
    border-radius: 2px !important;
    padding: 5px 0px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    color: #050f56;
    align-items: center;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 6px !important;
    cursor: pointer;
  }
  .card .card-info .card-info-header .review-container {
    display: flex;
    flex-direction: column-reverse !important;
    gap: 10px;
  }
}

.vehicle-card-container {
  width: 100%;
  margin: 0 auto;
  /* padding: 10px; */
}
.card {
  width: 100%;
  position: relative;
}

.card .user-vehicle-uid {
  position: absolute;
  top: 4rem;
  right: 6.5rem;
  background-color: #00adef;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card .user-vehicle-uid span {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}

@media screen and (max-width: 768px) {
  .card .user-vehicle-uid {
    padding: 0.3rem 0.5rem;
    top: 3.5rem;
    right: 4.5rem;
  }

  .card .user-vehicle-uid span {
    font-size: 0.4rem;
  }
}

.overlay-sold {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 600;
}
.overlay-sold img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  position: relative;
  bottom: 12px;
}

.vehicle-card-container .card {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* overflow: hidden; */
  margin: 0rem 0rem 3rem 0rem;
  align-items: center;
  /* padding: 0px 10px 0; */
  padding: 10px 10px;
}

.vehicle-card-container .card .card-wrapper-vehicles {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* padding: 10px 10px 0; */
}

.card .card-img-wrapper {
  /* background-color: #fe620d; */
  overflow: hidden;
  border-radius: 8px;
}

.card .card-img-wrapper img {
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .card-info {
  padding: 0px 10px;
  margin-bottom: 0px;
  width: 100%;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
}
.card .card-info .card-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card .card-info .card-info-header .card-info-title {
  display: flex;
  flex-direction: column;
}
.card .card-info .card-info-header .card-info-title .location {
  display: flex;
  align-items: center;
}
.card .card-info .card-info-header .card-info-title h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #050f56;
  margin: 0;
  padding: 0;
}
.card .card-info .card-info-header .card-info-title .location span {
  display: inline-block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;
  color: #121212;
}

.card .card-info .card-info-header .review-container {
  display: flex;
  gap: 10px;
}
.card .card-info .card-info-header .review-container .sold-btn {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  color: #050f56;
  align-items: center;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
}
.card .card-info .card-info-header .review-container .review-action {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.card .card-info .card-info-header .review-container .review-action img {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.card .card-info .card-info-header .card-publish-review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card .card-info .card-info-header .card-publish-review .review {
  border: 1px solid #fe620d;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fe620d;
}
.card .card-info .card-info-header .card-publish-review .review.published {
  background: rgba(0, 177, 39, 0.14);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card
  .card-info
  .card-info-header
  .card-publish-review
  .review.published
  strong {
  display: inline-block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #00b127;
  margin: 0;
}
.card .card-info .card-info-header .card-publish-review .review strong {
  display: inline-block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #fe620d;
  margin: 0;
}
.card .card-info .card-info-header .card-publish-review span {
  display: inline-block;
  margin-top: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  color: #000000;
}

.card .card-info .card-price {
  margin-top: 10px;
}
.card .card-info .card-price h3 {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #00adef;
}

.card .card-info .card-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  margin-top: 50px;
  width: 82%;
}
.card .card-info .card-stats .stat {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
}
.card .card-info .card-stats .stat span {
  display: inline-block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #121212;
}

.vehicle-card-container .card .card-btns {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.vehicle-card-container .card button {
  border: none;
  width: 100%;
  padding: 10px 0px;
  font-family: "Roboto";
  font-size: 16px;
  background: #050f56;
  border-radius: 5px;
  color: #ffffff;
}

.vehicle-card-container .card button:nth-child(2) {
  width: 20%;
  background: #ffffff;
  color: #050f56;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
}
.vehicle-card-container .card button:nth-child(3) {
  width: 20%;
  border: 1px solid #ff0000;
  background: #ffffff;
  color: #ff0000;
}

.vehicle-card-container .card .del-btn {
  background-color: rgb(233, 131, 131);
}
