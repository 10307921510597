.change-pwd-container .input-form-control {
  margin: 20px 0 0 0;
  position: relative;
}

.change-pwd-container .input-form-control h6 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.change-pwd-container .input-form-control input {
  border: none;
  margin-top: 5px;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.change-pwd-container .input-form-control input:focus {
  outline: none;
}
.change-pwd-container .input-form-control img {
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  right: 5px;
  cursor: pointer;
}

.change-pwd-container .submit-btn {
  margin-top: 20px;
}

.change-pwd-container .submit-btn button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
}
