.section-wrapper-container {
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .section-wrapper-container {
    width: 95%;
    margin: 0 auto;
  }
}
