.contact-wrapper {
  box-shadow: 0px 1px 16px 5px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin: 0 30px;
}
.contact-container {
  padding: 20px 20px;
  width: 100%;
}
.contact-container h4 {
  font-family: "Poppins", sans-serif;
  color: #050f56;
  font-weight: bold;
}
.contact-form-container .row {
  margin-top: 25px;
}
.contact-container .contact-form-container input {
  padding: 10px 20px;
}
.contact-container .contact-form-container input:focus {
  outline: none;
  border: none;
}
.city-input {
  position: relative;
}
.city-input img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 20px;
}

.city-dropdown-box {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  box-shadow: 0px 1px 16px 5px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  overflow: scroll;
}

.overlay-contact {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.contact-container .contact-form-container .submit-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container .contact-form-container .submit-btn button {
  padding: 10px 50px;
  width: 30%;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  background-color: #050f56;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.address-wrapper {
  box-shadow: 0px 1px 16px 5px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin: 0 30px;
  margin-top: 30px;
}

.address-container {
  display: flex;
  padding: 20px 20px;
  position: relative;
}

.left-address {
  flex: 1 0 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.left-address h4 {
  font-family: "Poppins", sans-serif;
  color: #050f56;
  text-align: center;
  font-weight: bold;
}
.left-address .contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.left-address .contact-details .contact-icons {
  background-color: #050f56;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}
.left-address .contact-details .contact-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.left-address .contact-details .contact-text {
  text-align: center;
  margin-top: 10px;
}
.left-address .contact-details .contact-text h6 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #4a4a4a;
}
.left-address .contact-details .contact-text p {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
  color: #787878;
}

.divider-line {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 90%;
  z-index: -1;
}
.divider-line span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #d2d2d2;
  border-radius: 50%;
}
.divider-line .line {
  width: 1px;
  height: 100%;
  background-color: #d2d2d2;
  margin: 0;
}

.right-address {
  flex: 1 0 50%;
}

.contact-illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 1000px) {
  .contact-wrapper {
    margin: 0 10px;
  }

  .address-wrapper {
    margin: 30px 10px;
  }
  .contact-container .contact-form-container input::placeholder {
    font-size: 16px;
  }
  .contact-container .contact-form-container textarea::placeholder {
    font-size: 16px;
  }
  .contact-container .contact-form-container .submit-btn button {
    width: 100%;
  }
  .address-container {
    flex-direction: column;
    gap: 20px;
  }
  .divider-line {
    display: none;
  }

  .contact-illustration img {
    width: 100%;
    height: 100%;
  }

  .row {
    flex-wrap: wrap;
    gap: 10px;
  }
}
