.details-container {
  margin-top: 20px;
}

.details-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.details-fields-container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 20px;
}

.form-input-control {
  margin: 15px 0 0 0;
}

.form-input-control h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}
.form-input-control input {
  border: none;
  width: 100%;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.form-input-control input:focus {
  outline: none;
}

.details-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 10px;
}
.details-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.details-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

@media screen and (max-width: 1000px) {
  .details-btns button {
    width: 100%;
  }

  .form-input-control input {
    -webkit-appearance: none;
  }
}
