.upload-container {
  margin-top: 20px;
}

.upload-container h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}

.upload-fields-container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 20px;
}

/* Row One */
.upload-row-one {
  display: flex;
}
.upload-row-one .upload-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 60px 0 0;
}
.upload-row-one .upload-img h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}
.upload-row-one .upload-img .upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 30px;
  cursor: pointer;
}
.upload-row-one .upload-img .upload-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  Row Two and Row Three */
.upload-row-two,
.upload-row-three {
  margin-top: 30px;
}
.upload-row-two h6,
.upload-row-three h6 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #050f56;
  margin: 0;
}
.upload-front-tyre {
  display: flex;
}
.upload-front-tyre .upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 50px 0 15px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 30px;
  cursor: pointer;
}
.upload-front-tyre .upload-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 10px;
}
.upload-btns button {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #050f56;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 30%;
}

.upload-btns .prev-btn {
  background-color: #ffffff;
  color: #050f56;
  border: 1px solid #919191;
}

/* .reactEasyCrop_Container {
  width: 800px;
  height: 500px;
} */

.modal-buttons {
  position: absolute;
  width: 500px !important;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-buttons button {
  width: 200px;
  border: none;
  background: #050f56;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 10px;
}
.modal-buttons .cancel-modal-button {
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: #050f56;
  border: 1px solid #ff0000;
}

@media screen and (max-width: 1000px) {
  .upload-row-one .upload-img {
    margin: 0 30px 0 0;
  }
  .upload-row-one .upload-img h6 {
    font-size: 8px;
  }

  .upload-row-one .upload-img .upload-box {
    padding: 15px 15px;
  }

  .upload-row-two h6,
  .upload-row-three h6 {
    font-size: 8px;
  }

  .upload-front-tyre .upload-box {
    margin: 10px 30px 0 0;
    padding: 15px 15px;
  }

  .upload-btns button {
    width: 100%;
  }

  /* .modal-buttons {
    bottom: -15px;
    left: 80%;
  } */

  .modal-buttons button {
    width: 100px;
  }
}
