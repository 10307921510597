.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* language section */

/* .goog-logo-link{
  display: none;
}
.goog-te-gadget .goog-te-combo{
  border: none;
}

.goog-te-combo:focus{
  outline: none;
}
.skiptranslate {
  font-size: 0px !important;
}
.goog-te-gadget{
  font-size: 0px !important;

}
.goog-te-gadget{
  position: fixed;
  z-index: 1000;
  top: 98px;
  right: 80px;
font-size: 0px;
}
.goog-te-gadget select{
  width: 105px;
  border: none;
  height: 48px; 
  background: #FFFFFF;
  border-radius: 8px;
  position: fixed;
  z-index: 999;
  top: 45px;
  right: 70px;
  }
  table{
    display: none !important;
    height: 0% !important;
  } 
  .goog-te-banner{
    display: none !important;
    height: 0px !important;
  }
  .goog-te-banner-frame{
  }
   */

body {
  top: 0 !important;
}
.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  visibility: hidden;
}

#google_translate_element {
  display: none;
}
.skiptranslate {
  display: none;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}
/* .website{
  display: inline-block !important;
} */

.goog-text-highlight {
  /* display: none !important */
  background-color: transparent !important;
  box-shadow: none !important;
}

#goog-gt-tt {
  display: none !important;
}

.loading-effect {
  /* background-color: #61dafb; */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.Toastify .Toastify__toast-container {
  z-index: 9999999;
}
