@media screen and (max-width: 1000px) {
  .user-order-header {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fe620d !important; */
  }
  .user-order-header h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #050f56;
    margin-bottom: 30px;
    position: relative;
  }
  .user-order-header h1::after {
    content: "";
    position: absolute;
    top: 110%;
    left: 0;
    width: 85px;
    height: 4px;
    border-radius: 5px;
    background-color: #fe620d;
  }
  .lottie-div {
    width: 60%;
  }
  .right-order-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right-order-container {
    /* background-color: #fe620d !important; */

    background: #ffffff;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.11);
    border-radius: 20px;
    width: 55%;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .order-msg {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #bababa;
    margin-bottom: 4rem;
  }

  .browse-veicles-button {
    height: 4rem;
    width: 17rem;
    background: #050f56;
    border-radius: 10px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }
  .seller-details-container {
    display: flex;
    flex-direction: column;
    /* background-color: #fe620d;
   */
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 7px !important;
    color: #878787;
    padding: 10px;
    gap: 10px !important;
    margin-top: 30px !important;
  }
  .seller-details-container .stat {
    /* margin-bottom: 20px !important; */
  }
  .text-color-blue {
    color: #050f56;
    font-weight: 600;
  }

  /* .card-img-wrapper img {
    width: 220px !important;
    height: 160px !important;
  }
  .card-img-wrapper {
    height: 160px !important;
    width: 220px !important;
    background-color: #fe620d !important;
  } */
  .mobile-enquiry-price {
    position: absolute !important;
    top: 20% !important;
  }
  .mobile-enquiry-img {
    height: 120px !important;
    width: 200px !important;
    border-radius: 5px !important;
  }
  .mobile-enquiry-img img {
    height: 100% !important;
    width: 100% !important;

    border-radius: 5px !important;
  }

  .filter-my-enquiries .brand-filter {
    padding: 10px 10px !important;
  }
  .filter-my-enquiries .model-filter {
    padding: 10px 10px !important;
  }
  .filter-my-enquiries .vehicle-no-filter img {
    display: none !important;
  }
  .filter-my-enquiries .vehicle-no-filter input {
    padding: 10px 10px !important;
  }

  .scroll-brand-model .text-content {
    margin-left: 10px !important;
  }
}
.user-order-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-order-header h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #050f56;
  margin-bottom: 30px;
  position: relative;
}
.user-order-header h1::after {
  content: "";
  position: absolute;
  top: 110%;
  left: 0;
  width: 85px;
  height: 4px;
  border-radius: 5px;
  background-color: #fe620d;
}
.lottie-div {
  width: 60%;
}
.right-order-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-order-container {
  background: #ffffff;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  width: 55%;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.order-msg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #bababa;
  margin-bottom: 4rem;
}

.browse-veicles-button {
  height: 4rem;
  width: 17rem;
  background: #050f56;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}
.seller-details-container {
  display: flex;
  flex-direction: column;
  /* background-color: #fe620d;
     */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #878787;
  padding: 10px;
  gap: 20px;
  margin-top: 15px;
}
.seller-details-container .stat {
  /* margin-bottom: 20px !important; */
}
.text-color-blue {
  color: #050f56;
  font-weight: 600;
}

.card-img-wrapper img {
  /* width: 220px !important; */
  height: 160px !important;
}
.card-img-wrapper {
  height: 160px !important;
  width: 220px !important;
}

.filter-my-enquiries {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.filter-my-enquiries .brand-filter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px 20px;
  cursor: pointer;
  width: 100%;
}
.filter-my-enquiries .brand-filter h5 {
  font-family: "Poppins";
  color: #989898;
  margin: 0;
  font-size: 16px;
}
.filter-my-enquiries .brand-filter img {
  width: 20px;
  height: 20px;
}
.filter-my-enquiries .model-filter {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  cursor: pointer;
  width: 100%;
}
.filter-my-enquiries .model-filter h5 {
  font-family: "Poppins";
  color: #989898;
  margin: 0;
  font-size: 16px;
}
.filter-my-enquiries .model-filter img {
  width: 20px;
  height: 20px;
}
.filter-my-enquiries .vehicle-no-filter {
  width: 100%;
  position: relative;
}
.filter-my-enquiries .vehicle-no-filter img {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}
.filter-my-enquiries .vehicle-no-filter input {
  width: 100%;
  padding: 18px 40px;
  border: none;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
}
.filter-my-enquiries .vehicle-no-filter input:focus {
  outline: none;
}
.filter-my-enquiries .vehicle-no-filter input::placeholder {
  font-size: 16px;
}

.dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 500;
}

.scroll-brand-model {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  height: 200px;
}
.scroll-brand-model .text-content {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}
